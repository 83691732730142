import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import upload from "../../images/Upload.svg";
import cut from "../../images/close-circle.svg";
import trash from "../../images/Delete.svg";
import NoIndex from "../NoIndex";
import { toast, ToastContainer } from "react-toastify";
const Catalogue = ({userID}) => {
  const [items, setItems] = useState([
    {
      image: null,
      title: "",
      description: "",
      websiteLink: "",
      price: "",
      salePrice: "",
      facebookCategory: "",
      condition: "New",
      availability: "In stock",
      status: "Active",
      brand: "",
      base64: "",
      contentID: "",
    },
  ]);
  const [imageLink, setImageLink] = useState("");
  const handleInputChange = (index, event) => {
    const values = [...items];
    const { name, value, files } = event.target;

    if (name === "image" && files.length > 0) {
      const file = files[0];
      values[index][name] = file;

      const reader = new FileReader();
      reader.onloadend = () => {
        values[index].base64 = reader.result;
        setItems(values);
      };
      reader.readAsDataURL(file);
    } else {
      values[index][name] = value;
      setItems(values);
    }
  };
  const handleRemoveImage = (index) => {
    const values = [...items];
    values[index].image = null;
    values[index].base64 = null;
    setItems(values);
  };
  const handleAddItem = () => {
    setItems([
      ...items,
      {
        image: null,
        title: "",
        description: "",
        websiteLink: "",
        price: "",
        salePrice: "",
        facebookCategory: "",
        condition: "New",
        availability: "In stock",
        status: "Active",
        brand: "",
        contentID:"",
      },
    ]);
  };
  const handleRemoveItem = (index) => {
    const values = [...items];
    values.splice(index, 1);
    setItems(values);
  };
  const convertImage = async () => {
    try {
      const result = await Swal.fire({
        title: "Confirmation",
        text: "Are you sure you want to upload the Catalogue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, upload it!",
      });
      if (result.isConfirmed) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
          },
        };
        const requestBody = {
          userid: userID,
          item_name: items[0].title,
          type: "IMG",
          image_data: items[0].base64,
        };
        const response = await axios.post(
          "https://bumppy.in/anlook/api/v1/uploadItemImage.php",
          requestBody,
          config
        );
        console.log(response.data.image_url);
        if (response.data.status === "0") {
          setImageLink(response.data.image_url);
          console.log(imageLink);
          // singleCatalogueUploadAPI();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (imageLink) {
      console.log("Image link updated:", imageLink);
      singleCatalogueUploadAPI();
    }
  }, [imageLink]);
  const singleCatalogueUploadAPI = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
        },
      };
      const requestBody = {
        userid: userID,
        title: items[0].title,
        description: items[0].description,
        availability: "string",
        condition: "string",
        price: items[0].price,
        link: items[0].websiteLink,
        image_link: imageLink,
        brand: "string",
        google_product_category: "string",
        fb_product_category: "string",
        quantity_to_sell_on_facebook: "string",
        sale_price: items[0].salePrice,
        sale_price_effective_date: "string",
        item_group_id: "string",
        gender: "string",
        color: "string",
        size: "string",
        age_group: "string",
        material: "string",
        pattern: "string",
        shipping: "string",
        shipping_weight: "string",
        videoUrl: "string",
        videoTag: "string",
        style: "string",
      };
      const response = await axios.post(
        "https://api.anlook.com/api/Whatsapp/addCatalogItemUpload",
        requestBody,
        config
      );
      if (response.data.responseCode === "00") {
        console.log("Success");
        toast.success("Item added successfully!"); // Display success toast
        clearForm();
      } else {
        toast.error("Failed to add item."); // Optionally handle other response codes
      }
    } catch (error) {
      console.error("Error adding item:", error);
      toast.error("An error occurred while adding the item."); // Display error toast
    }
  };
  const clearForm = () => {
    setItems([
      {
        image: null,
        title: "",
        description: "",
        websiteLink: "",
        price: "",
        salePrice: "",
        facebookCategory: "",
        condition: "New",
        availability: "In stock",
        status: "Active",
        brand: "",
        base64: "",
        contentID: "",
      },
    ]);
    setImageLink("");
  };
  return (
    <div
    className="p-4 bg-white"
    style={{
      // backgroundColor: "#F8F8F8",
      fontFamily: "Nunito, sans-serif",
    }}
  >
           <NoIndex />
      <div className="bg-white px-4">
      <ToastContainer />
    <div className="d-flex justify-content-between align-items-end pb-3 ">
        <div className="CreateCampaign">Add Item in Catalogue</div>

        <div>
          <button
            className="btn bg-green-filter text-white"
            onClick={handleAddItem}
          >
            + Add Items
          </button>
        </div>
      </div>
      <div
        className="table-responsive border-15 "
        style={{ border: "1px solid #ccc" }}
      >
        <table className="table p-0 m-0">
          {/* <thead>
            <tr>
              <th>Image</th>
              <th>Title</th>
              <th>Description</th>
              <th>Website Link</th>
              <th>Price</th>
              <th>Sale Price</th>
              <th>Actions</th>
            </tr>
          </thead> */}
          <tbody>
            {items.map((item, index) => (
              <tr key={index} className="">
                {/* <td className="py-4 ps-4" >
                 <div className="">
                  <input
                    type="file"
                    name="image"
                    onChange={(event) => handleInputChange(index, event)}
                    className=""
                  />
                  </div>
                </td> */}
                <td className="py-4  ">
                  {!item.image ? (
                    <>
                        <div className="file-input-container d-flex justify-content-center">
                          <label
                            className="custom-file-label"
                          >
                            <img src={upload} alt="Upload" />
                            <input
                              type="file"
                              name="image"
                              onChange={(event) =>
                                handleInputChange(index, event)
                              }
                              className="file-input"
                            />
                          </label>
                        </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <div className="d-flex justify-content-center">
                          <div className=" image-container">
                            <img
                              src={URL.createObjectURL(item.image)}
                              alt="Preview"
                              style={{ width: "200px", maxHeight: "200px", objectFit: "contain" }}
                            />
                            <button
                              className="overlay-button"
                              onClick={() => handleRemoveImage(index)}
                            >
                              <img src={cut} alt="" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </td>
                {/* Col 1 */}
                <td className="py-4">
                  <label className="text-green text-5 pb-2 ">Item Name</label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Enter Item Name"
                    value={item.title}
                    onChange={(event) => handleInputChange(index, event)}
                    className="form-control"
                  />
                  <label className="text-green text-5 pb-2 pt-3">Website Link</label>
                  <input
                    type="text"
                    name="websiteLink"
                    placeholder="Enter Website Link"
                    value={item.websiteLink}
                    onChange={(event) => handleInputChange(index, event)}
                    className="form-control"
                  />
                </td>
                {/* col - 2 */}
                <td className="py-4">
                  <label className="text-green text-5 pb-2">Content Id</label>
                  <input
                    type="text"
                    name="contentID"
                    placeholder="Enter Content Id"
                    value={item.contentID}
                    onChange={(event) => handleInputChange(index, event)}
                    className="form-control"
                  />
                  <label className="text-green text-5 pb-2 pt-3" >Price</label>
                  <input
                    type="text"
                    name="price"
                    placeholder="Enter Price"
                    value={item.price}
                    onChange={(event) => handleInputChange(index, event)}
                    className="form-control"
                  />
                </td>
                {/* Col-3 */}
                <td className="py-4" >
                  <label className="text-green text-5 pb-2">Description</label>
                  <input
                    type="text"
                    name="description"
                    placeholder="Enter Description"
                    value={item.description}
                    onChange={(event) => handleInputChange(index, event)}
                    className="form-control"
                  />
                  <label className="text-green text-5 pb-2 pt-3">Sale Price</label>
                  <input
                    type="text"
                    name="salePrice"
                    placeholder="Enter Sale Price"
                    value={item.salePrice}
                    onChange={(event) => handleInputChange(index, event)}
                    className="form-control"
                  />
                </td>
                <td className="py-4 align-middle " >
                  <div className="  cursor-pointer py-5 px-2" style={{borderLeft:"1px solid #ccc"}}>
                    <div className="d-flex justify-content-center " onClick={() => handleRemoveItem(index)}>
                      <img src={trash} alt="" />
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-start py-4">
        <button
          className="btn bg-green-filter text-white"
          onClick={(e) => {
            convertImage();
          }}
        >
          Upload Items
        </button>
      </div>
    </div>
    </div>
  );
};
export default Catalogue;
