import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faClipboardList,
  faMoneyBillWave,
  faFileAlt,
  faBlog,
  faComments,
  faUserTie,
  faSignOutAlt,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import logoImage from "../../images/LogoWithAnlook.svg";

const NestedSideNav = ({ logout }) => {
  const [activeItem, setActiveItem] = useState("");
  const [expandedItem, setExpandedItem] = useState(null); // Changed to single item
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1194);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 1194);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const path = location.pathname.split("/")[2] || "";
    setActiveItem(path.toLowerCase());
    
    // Find if current path matches any subitems and expand their parent
    const findParentAndExpand = () => {
      for (const item of anavItemsData) {
        if (item.subItems) {
          const matchingSubItem = item.subItems.find(
            subItem => subItem.menu_url.split("/")[2].toLowerCase() === path.toLowerCase()
          );
          if (matchingSubItem) {
            setExpandedItem(item.menu_id);
            break;
          }
        }
      }
    };
    findParentAndExpand();
  }, [location]);

  const anavItemsData = [
    {
      menu_id: "1",
      menu_title: "Dashboard",
      icon: faTachometerAlt,
      menu_url: "/admin/home",
      menu_status: "active",
    },
    {
      menu_id: "2",
      menu_title: "Clients",
      icon: faUsers,
      menu_url: "/admin/clients",
      menu_status: "active",
    },
    {
      menu_id: "3",
      menu_title: "Finance",
      icon: faMoneyBillWave,
      menu_status: "active",
      subItems: [
        {
          menu_id: "3-1",
          menu_title: "Payout",
          menu_url: "/admin/payout",
          menu_status: "active",
        },
        {
          menu_id: "3-2",
          menu_title: "Orders",
          menu_url: "/admin/orders",
          menu_status: "active",
        },
      ],
    },
    {
      menu_id: "4",
      menu_title: "Logs",
      icon: faClipboardList,
      menu_url: "/admin/logs",
      menu_status: "active",
    },
    {
      menu_id: "5",
      menu_title: "Communication",
      icon: faComments,
      menu_status: "active",
      subItems: [
        {
          menu_id: "5-1",
          menu_title: "WhatsApp",
          menu_url: "/admin/whatsapp",
          menu_status: "active",
        },
        {
          menu_id: "5-2",
          menu_title: "Chats",
          menu_url: "/admin/AdminChat",
          menu_status: "active",
        },
      ],
    },
    {
      menu_id: "6",
      menu_title: "Employee",
      icon: faUserTie,
      menu_url: "/admin/employee",
      menu_status: "active",
    },
    {
      menu_id: "7",
      menu_title: "Blogs",
      icon: faBlog,
      menu_status: "active",
      subItems: [
        {
          menu_id: "7-1",
          menu_title: "Add Blog",
          menu_url: "/admin/blogs",
          menu_status: "active",
        },
        {
          menu_id: "7-2",
          menu_title: "All Blogs",
          menu_url: "/admin/allblogs",
          menu_status: "active",
        },
        {
          menu_id: "7-3",
          menu_title: "Authors",
          menu_url: "/admin/Authors",
          menu_status: "active",
        },
      ],
    },
  ];

  const toggleExpand = (itemId) => {
    // If clicking the same item, close it. If clicking a different item, open it and close the previous one
    setExpandedItem(expandedItem === itemId ? null : itemId);
  };

  const renderNavItem = (item) => {
    const isActive = activeItem === item.menu_url?.split("/")[2]?.toLowerCase();
    const hasSubItems = item.subItems && item.subItems.length > 0;
    const isExpanded = expandedItem === item.menu_id;

    return (
      <li
        key={item.menu_id}
        className={`nav-item ${isActive ? "active" : ""} ${
          isExpanded ? "expanded" : ""
        }`}
      >
        <Link
          to={hasSubItems ? "#" : item.menu_url}
          className="nav-link"
          onClick={(e) => {
            if (hasSubItems) {
              e.preventDefault();
              toggleExpand(item.menu_id);
            } else {
              setActiveItem(item.menu_url.split("/")[2].toLowerCase());
            }
          }}
        >
          <FontAwesomeIcon icon={item.icon} className="nav-icon" />
          <span>{item.menu_title}</span>
          {hasSubItems && (
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`expand-icon ${isExpanded ? "rotated" : ""}`}
            />
          )}
        </Link>
        {hasSubItems && isExpanded && (
          <ul className="sub-nav my-0">
            {item.subItems.map((subItem) => (
              <li
                key={subItem.menu_id}
                className={`nav-item my-0 ${
                  activeItem === subItem.menu_url.split("/")[2].toLowerCase()
                    ? "active"
                    : ""
                }`}
              >
                <Link
                  to={subItem.menu_url}
                  className="nav-link"
                  onClick={() =>
                    setActiveItem(subItem.menu_url.split("/")[2].toLowerCase())
                  }
                >
                  <span>{subItem.menu_title}</span>
                </Link>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  };

  return (
    <nav className={`side-nav ${isMobile ? "mobile" : ""}`}>
      <div className="Brandlog">
        <img src={logoImage} alt="Anlook" style={{ height: "3rem" }} />
        <hr />
      </div>
      <ul>
        {anavItemsData
          .filter((item) => item.menu_status === "active")
          .map(renderNavItem)}
      </ul>
      <div className="logOut">
        <div className="nav-link-logout" onClick={logout}>
          <FontAwesomeIcon icon={faSignOutAlt} className="nav-icon" />
          <span className="nav-label">Logout</span>
        </div>
      </div>
    </nav>
  );
};

export default NestedSideNav;