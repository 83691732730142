import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios"; // assuming you use axios for API calls
import LandingNav from "./Landing_Nav";
import { Helmet } from "react-helmet";
import LandingFooter from "./Landing_Footer";
import { Link } from "react-router-dom";
import author from "../../images/LandingPageImage/Author_img.svg";
import callImage from "../../images/LandingPageImage/cta_img_1x.webp";
import CTA from "./Landing_CTA";
import whatsapp from "../../images/LandingPageImage/Whatsapp.svg";
import twitter from "../../images/LandingPageImage/X-Twitter.svg";
import yt from "../../images/LandingPageImage/Yt.svg";
import fb from "../../images/LandingPageImage/FB.svg";
import BlogSection from "./Home_Page/Blog_section";
const Post = ({ onSignUpClick,onLoginClick ,country,handleCountryChange  }) => {
  const location = useLocation();
  const [blogContent, setBlogContent] = useState(null);
  const [error, setError] = useState(null);
  const[publishedData , setPublishData]=useState([])
  const [blogData, setBlogData] = useState([]);
  useEffect(() => {
    axios
      .post("https://api.goseo.in/api/Seo/GetProductBlogs", {
        product_name: "anlook",
      })
      .then((response) => {
        if (response.data.status === "0") {
          const apiData = response.data.data
            .slice(0, 10) // Limit to at most 10 posts
            .map((post) => ({
              id: post.post_id,
              image: post.post_featured_image,
              title: post.post_title,
              description: post.post_excerpt,
              author: post.post_author,
              post_slug: post.post_slug,
            }));
          setBlogData(apiData);
        }
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
      });
  }, []);

  // Function to get the slug part from the URL
  const getSlugFromUrl = () => {
    const url = window.location.pathname; // Get the path from the URL
    const slug = url.split("/").pop(); // Extract the last part of the URL (the slug)
    return slug;
  };
  useEffect(() => {
    const slug = getSlugFromUrl();

    if (slug) {
      // Call API to get blog post content dynamically using the slug
      const fetchBlogPost = async () => {
        try {
          const response = await axios.post(
            "https://api.goseo.in/api/Seo/GetPublishedBlogPost",
            {
              p_slug: slug,
            }
          );
          if (response.data.status === "0") {
            setBlogContent(response.data.data[0]);
            setPublishData(response.data.data)
            // console.log(publishedData)
          } else {
            setError("Failed to fetch the blog post.");
          }
        } catch (err) {
          setError("An error occurred while fetching the blog content.");
        }
      };
      fetchBlogPost();
    }
  }, [location]);
  if (error) {
    return <div>Error: {error}</div>;
  }
  // if (!blogContent) {
  //   return (

  //     <div className="post-shimmer-container">
  //       <div className="post-card-shimmer">
  //         <div className="post-title-shimmer"></div>
  //         <div className="post-author-shimmer"></div>
  //         <div className="post-tags-shimmer"></div>
  //         <div className="post-image-shimmer"></div>
  //         <div className="post-content-shimmer"></div>
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <>
    <Helmet>
    {/* SEO Meta Tags */}
    <title>{publishedData.post_title}</title>
    <meta name="description" content={publishedData.post_excerpt} />
    <meta name="keywords" content={publishedData.post_keywords} />

    {/* Open Graph Meta Tags */}
    <meta property="og:title" content={publishedData.post_title} />
    <meta property="og:description" content={publishedData.post_excerpt} />
    <meta property="og:image" content={publishedData.post_featured_image} />
    {/* <meta
      property="og:url"
      content={`https://billpunch.com/blog/${post_slug}`}
    /> */}
    <meta property="og:type" content="article" />

    {/* Twitter Meta Tags */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={publishedData.post_title} />
    <meta name="twitter:description" content={publishedData.post_excerpt} />
    <meta name="twitter:image" content={publishedData.post_featured_image} />
    <script type="application/ld+json">{publishedData.schema_json}</script>
  </Helmet>
   
      <div className="bg-white">
      <LandingNav onSignUpClick={onSignUpClick}  onLoginClick={onLoginClick}  country={country} handleCountryChange={handleCountryChange}  />
        <div className="padding-5-rem pt-5">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className=" border-radius-20">
                {!blogContent ? (
                  <div className="p-5">
                    <div className="post-shimmer-wrapper post-shimmer-title"></div>
                    <div className="post-shimmer-wrapper post-shimmer-text"></div>
                    <div className="post-shimmer-wrapper post-shimmer-text"></div>
                    <div className="post-shimmer-wrapper post-shimmer-image"></div>
                    <div className="post-shimmer-wrapper post-shimmer-text"></div>
                    <div className="post-shimmer-wrapper post-shimmer-text"></div>
                  </div>
                ) : (
                  <>
                    <div className="text-post-title px-lg-2 px-2">
                      {blogContent.post_title}
                    </div>
                    <div className="px-lg-2 py-3 px-2 d-flex align-items-center justify-content-between flex-wrap">
                      <div className="d-flex align-items-center mb-2 mb-lg-0">
                        <div>
                          <img
                            src={author}
                            className="pe-0 me-2"
                            alt="author"
                            height={40}
                          />
                        </div>
                        <div className="text-post-author">
                          <div>{blogContent.post_author}</div>
                          <div className="text-post-author">
                            {/* On {new Date(blogContent.created_at).toLocaleDateString()} */}
                            On  {new Date(blogContent.created_at).toLocaleDateString('en-GB')}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end gap-2 mt-2 mt-lg-0 px-2">
  <div>
    <a 
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img src={fb} alt="Facebook" height={30} />
    </a>
  </div>
  <div>
    <a 
      href={`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}&text=Check out this article!`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img src={twitter} alt="Twitter" height={30} />
    </a>
  </div>
  <div>
  <a href="https://www.youtube.com/channel/UC1Z5I3Rb-9PGwfSXBI-jiSQ" target="_blank" rel="noopener noreferrer">
      <img src={yt} alt="YouTube" height={30} />
    </a>
  </div>
  <div>
    <a 
      href={`https://wa.me/?text=${encodeURIComponent(window.location.href)}`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img src={whatsapp} alt="WhatsApp" height={30} />
    </a>
  </div>
</div>
                    </div>
                    <div className="px-lg-2 px-2 d-flex justify-content-center align-items-center">
                      <img
                        src={blogContent.post_featured_image}
                        style={{ width: "100%" }}
                        alt={blogContent.post_featured_image_alt}
                        title={blogContent.post_featured_image_title}
                        className="border-15-only"
                      />
                    </div>
                    <div className="p-2 p-lg-2">
                      <div
                        className="blog-content"
                        dangerouslySetInnerHTML={{
                          __html: blogContent.post_content,
                        }}
                      />
                    </div>
                    <div className="d-flex flex-wrap justify-content-start px-lg-2 px-2 text-post-author gap-2">
  {blogContent.post_keywords
    .split(",")
    .map((tag, index) => (
      <p
        key={index}
        className="bg-gray-post p-1 m-0 px-2 border-15-only text-nowrap"
      >
        {tag}
      </p>
    ))}
</div>
                    <hr className="px-2" />
                    <div>
                      <div className="text-3-2 px-2">Share this article</div>
                      <div className="d-flex justify-content-start gap-2 mt-2 mt-lg-2 px-2">
  <div>
    <a 
      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img src={fb} alt="Facebook" height={30} />
    </a>
  </div>
  <div>
    <a 
      href={`https://twitter.com/share?url=${encodeURIComponent(window.location.href)}&text=Check out this article!`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img src={twitter} alt="Twitter" height={30} />
    </a>
  </div>
  <div>
  <a href="https://www.youtube.com/channel/UC1Z5I3Rb-9PGwfSXBI-jiSQ" target="_blank" rel="noopener noreferrer">
      <img src={yt} alt="YouTube" height={30} />
    </a>
  </div>
  <div>
    <a 
      href={`https://wa.me/?text=${encodeURIComponent(window.location.href)}`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <img src={whatsapp} alt="WhatsApp" height={30} />
    </a>
  </div>
</div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-4  col-12 pt-lg-0 pt-5">
              <div className="similar-blogs-container">
                <p className="text-top">Featured Post</p>
                <hr />
                <div className="similar-blogs-list">
                  {blogData.length > 0 ? (
                    blogData.map((blog) => (
                      <div
                        key={blog.id}
                        className="similar-blog-item similar-card"
                      >
                        <div className="row col-12 p-0 m-0 no-gutters">
                          <div className="col-4 p-0 m-0">
                            <div className="py-3 ps-3 d-flex justify-content-center align-items-center">
                              <img
                                src={blog.image}
                                alt={blog.title}
                                className="similar-blog-image"
                              />
                            </div>
                          </div>
                          <div className="col-8 p-0 m-0">
                            <div className="similar-card-body">
                              <p className="card-title">
                                <div className="text-3-2-dark-notbold">
                                  {blog.title}
                                </div>
                              </p>
                              <Link
                                to={`/blog/${blog.post_slug}`}
                                className="read-more-post"
                              >
                                Read more →
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Loading..</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlogSection />
        <CTA/>
        <LandingFooter />
      </div>
    </>
  );



  
};
export default Post;