import React, { useEffect } from 'react';

const ExternalChatBot = () => {

  useEffect(() => {
    // Check if Tawk.to script is already included
    if (!window.Tawk_API) {
      var Tawk_API = Tawk_API || {};
      var Tawk_LoadStart = new Date();
      // Create the script element and set properties
      const script = document.createElement("script");
      script.src = 'https://embed.tawk.to/672dfde04304e3196adf4c7d/1ic5qfe6s';
      script.async = true;
      script.charset = 'UTF-8';
      script.setAttribute('crossorigin', '*');

      // Append the script to the document head or body
      document.body.appendChild(script);

      // Clean up the script when component unmounts
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);


    
  return null; 
  
};

export default ExternalChatBot;
