import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Authorblogmodal } from "./Modal";
import "./AdminDashboard.css"
const Blogauthor = () => {
  const [authors, setAuthors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [Authormodal, setAuthormodal] = useState(false);
  const GetAuthorsBlog = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          slug: "all",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetAuthorsBlog`,
        requestOptions
      );
      const data = await res.json();
      console.log("Geteditloadblog response:", data);

      if (data.status === "0") {
        setAuthors(data.data);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };
  const filteredAuthors = authors.filter(author =>
    author.fullname.toLowerCase().includes(searchTerm.toLowerCase())
  );
  useEffect(() => {
    GetAuthorsBlog();
  }, []);

  return (
    <div className="marginRight pt-2">
        <div className="d-flex justify-content-center">
          <h2 className="">Authors</h2>
          <hr />
        </div>
      <div className="row mt-3 ms-4">
        <div className="col d-flex justify-content-center">
        <div  className="mr-2 me-2">
        <input className="p-2"
          type="text"
          placeholder="Search authors..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            width: "100%",
            borderRadius: ".4rem",
            border: "1px solid #ccc",height:"2.2rem"
          }}
        />
      </div>
          <Button
          style={{height:"2rem"}}
            variant="primary"
            size="sm"
            onClick={() => setAuthormodal(true)}
          >
            Add Author
          </Button>
        </div>
      </div>
      <Row className="g-4 mx-4 mt-2">
        {filteredAuthors.map((author) => (
          <Col key={author.id} md={2} sm={4}>
            <a
              href={`/author/${author.slug}`}
              target="_blank"
              rel="noopener noreferrer" style={{textDecoration:"none"}}
              onClick={() => {
                const authorData = JSON.stringify(author);
                console.log("click hua");
                localStorage.setItem("author", authorData);
                console.log("SessionStorage Author:", sessionStorage.getItem("author")); 
              }}
              
            >
              <Card className="p-1 w-100 h-100 shadow-none hover  ">
                <div
                  style={{
                    width: "100%",
                    height: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  {author.profile_image ? (
                    <Card.Img
                      variant="top"
                      src={author.profile_image}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#888",
                      }}
                    >
                      <FaUserCircle size={100} />
                    </div>
                  )}
                </div>
                <p className="m-1 d-flex justify-content-center" style={{color:"#000",fontSize:"0.8rem"}}>
                  <b>{author.fullname}</b>
                </p>
              </Card>
            </a>
          </Col>
        ))}
      </Row>
      <Authorblogmodal
        Authormodal={Authormodal}
        setAuthormodal={setAuthormodal}
        GetAuthorsBlog={GetAuthorsBlog}
      />
    </div>
  );
};

export default Blogauthor;
