import React, { useState, useEffect, useRef } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row, Col, Form, Card, Badge } from "react-bootstrap";
import { FaStore } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast, ToastContainer } from "react-toastify";

import {
 FaClock,
 FaPhoneAlt,
 FaMapMarkerAlt,
 FaEye,
 FaRoute,
} from "react-icons/fa";

import NoIndex from "../../NoIndex";

import Swal from "sweetalert2";

import "jspdf-autotable";

import axios from "axios";
const Doordash = ({ userID }) => {
 const [loading, setLoading] = useState(false);
 const [stores, setstores] = useState([]);
 const [StoreDetails, setStoreDetails] = useState({});
 const [Doordash, setDoordash] = useState([]);
 const [PhoneNumber, setPhoneNumber] = useState("");
 const [storeName, setstoreName] = useState('');
 const [storeAddress, setstoreAddress] = useState("")
 const [activeTab, setActiveTab] = useState("create-store-tab");

 const CreateStoreCall = async () => {
 setLoading(true);
 try {
 const config = {
 headers: {
 "Content-Type": "application/json",
 Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
 },
 };
 const UserDetail = {
 userid: userID,
 phoneNumber: PhoneNumber,
 storeName: storeName,
 storeAddress: storeAddress,
 };

 const response = await axios.post(
 "https://api.anlook.com/api/Doordash/createStore",
 UserDetail,
 config
 );
 if (response.data.responseCode === "00") {
 toast.success(response.data.responseMessage);

 setstores(response.data.response);
 console.log(response.data.response);
 console.log(stores);
 setLoading(false);
 } else {
 toast.error(response.data.responseMessage);
 }
 } catch (error) {
 toast.error("error");
 console.error("Error updating Admin login:", error);
 } finally {
 setLoading(false);
 setPhoneNumber("");
 setstoreAddress("");
 setstoreName("")
 }
 };
 const FetchStorestCall = async () => {
 setLoading(true);
 try {
 const config = {
 headers: {
 "Content-Type": "application/json",
 Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
 },
 };
 const UserDetail = {
 // userid: User.pkid,
 userid: userID,
 };

 const response = await axios.post(
 "https://api.anlook.com/api/Doordash/fetchStoreList",
 UserDetail,
 config
 );
 if (response.data.responseCode === "00") {
 // toast.success(response.data.responseMessage);

 setstores(response.data.response.result);
 console.log(response.data.response.result);
 console.log(stores);
 setLoading(false);
 } else {
 toast.error(response.response.responseMessage);
 }
 } catch (error) {
 toast.error("error");
 console.error("Error updating Admin login:", error);
 } finally {
 setLoading(false);
 }
 };
 const FetchStoreDetailtCall = async (id) => {
 setLoading(true);
 try {
 const config = {
 headers: {
 "Content-Type": "application/json",
 Authorization: "Basic YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=",
 },
 };
 const UserDetail = {
 // userid: User.pkid,
 userid: id,
 };

 const response = await axios.post(
 "https://api.anlook.com/api/Doordash/fetchStoreDetails",
 UserDetail,
 config
 );
 if (response.data.responseCode === "00") {
 // toast.success(response.data.responseMessage);

 setStoreDetails(response.data.response);
 console.log(response.data.response);
 console.log(StoreDetails);
 setLoading(false);
 } else {
 toast.error(response.response.responseMessage);
 }
 } catch (error) {
 toast.error("error");
 console.error("Error updating Admin login:", error);
 } finally {
 setLoading(false);
 }
 };
 useEffect(() => {
 FetchStorestCall();
 setActiveTab("create-store-tab")
 }, []);

 // const stores = [
 // {
 // id: 1,
 // name: "Pizza Paradise",
 // cuisine: "Italian",
 // address: "123 Main St, New York, NY",
 // phone: "(555) 123-4567",
 // hours: "9:00 AM - 10:00 PM",
 // status: "active",
 // image: "/api/placeholder/80/80",
 // },
 // {
 // id: 2,
 // name: "Burger Bliss",
 // cuisine: "American",
 // address: "456 Oak Ave, Brooklyn, NY",
 // phone: "(555) 234-5678",
 // hours: "10:00 AM - 11:00 PM",
 // status: "inactive",
 // image: "/api/placeholder/80/80",
 // },
 // {
 // id: 3,
 // name: "Sushi Supreme",
 // cuisine: "Japanese",
 // address: "789 Pine Rd, Queens, NY",
 // phone: "(555) 345-6789",
 // hours: "11:00 AM - 10:00 PM",
 // status: "active",
 // image: "/api/placeholder/80/80",
 // },
 // ];

 return (
 <div className="p-4 light" style={{ fontFamily: "Nunito, sans-serif" }}>
 <NoIndex />

 <div className="d-flex justify-content-center text-center">
 <ToastContainer />
 </div>
 <div className="bg-white px-4" style={{ borderRadius: "10px" }}>
 {/* <img src={organizationDetails} alt="" /> */}
 <div className="d-flex justify-content-between align-items-center border-15-only px-2 py-1">
 <div className="CreateCampaign">DoorDash</div>
 </div>
 {loading ? (
 <div className="m-5 p-5 d-flex justify-content-center">
 <div className="loader"></div>
 </div>
 ) : (
 <>
 {Doordash ? (
 <>
 <ul className="nav">
 <li className="nav-item" style={{ color: "black" }}>
 <button
 className={`nav-link ${
 activeTab === "create-store-tab"
 ? "active active-underline"
 : "gray-underline"
 }`}
 id="create-store-tab"
 data-bs-toggle="tab"
 data-bs-target="#create-store-tab-pane"
 type="button"
 role="tab"
 aria-controls="create-store-tab-pane"
 aria-selected="false"
 style={{
 color: "#01C37D",
 background: "none",
 border: "none",
 }}
 onClick={() => {
 setActiveTab("create-store-tab");
 }}
 >
 Create Store
 </button>
 </li>
 <li className="nav-item" style={{ color: "black" }}>
 <button
 className={`nav-link ${
 activeTab === "store-list-tab"
 ? "active active-underline"
 : "gray-underline"
 }`}
 id="store-list-tab"
 data-bs-toggle="tab"
 data-bs-target="#store-list-tab-pane"
 type="button"
 role="tab"
 aria-controls="store-list-tab-pane"
 aria-selected="false"
 style={{
 color: "#01C37D",
 background: "none",
 border: "none",
 }}
 onClick={() => {
 setActiveTab("store-list-tab");
 }}
 >
 Store List
 </button>
 </li>
 <li className="nav-item" style={{ color: "black" }}>
 <button
 className={`nav-link ${
 activeTab === "delivery-tab"
 ? "active active-underline"
 : "gray-underline"
 }`}
 id="delivery-tab"
 data-bs-toggle="tab"
 data-bs-target="#delivery-tab-pane"
 type="button"
 role="tab"
 aria-controls="delivery-tab-pane"
 aria-selected="false"
 style={{
 color: "#01C37D",
 background: "none",
 border: "none",
 }}
 onClick={() => {
 setActiveTab("delivery-tab");
 }}
 >
 Delivery
 </button>
 </li>
 </ul>
 <div className="tab-content p-0 py-2" id="myTabContent">
 {/* Running Active Order */}
 {/* Create Order */}
 <div
 className="tab-pane fade show active"
 id="create-store-tab-pane"
 role="tabpanel"
 aria-labelledby="create-store-tab"
 >
 <Container className="py-4 w-100">
 <Card className="shadow-sm w-50">
 <Card.Header className="bg-success text-white">
 <div className="d-flex align-items-center gap-2">
 <FaStore size={20} />
 <h4 className="mb-0">DoorDash Store Setup</h4>
 </div>
 </Card.Header>

 <Card.Body>
 <Form>
 {/* Basic Information */}
 <section className="mb-4">
 <h5 className="text-primary mb-3">
 Basic Information
 </h5>
 <Row className="g-3">
 <Col md={6}>
 <Form.Group>
 <Form.Label>Store Name</Form.Label>
 <Form.Control
 type="text"
 value={storeName}
 onChange={(e)=>setstoreName(e.target.value)}
 placeholder="Enter store name"
 />
 </Form.Group>
 </Col>

 <Col md={6}>
 <Form.Group>
 <Form.Label>Cuisine Type</Form.Label>
 <Form.Select>
 <option value="">
 Select cuisine type
 </option>
 <option value="american">American</option>
 <option value="italian">Italian</option>
 <option value="chinese">Chinese</option>
 <option value="mexican">Mexican</option>
 <option value="indian">Indian</option>
 </Form.Select>
 </Form.Group>
 </Col>
 </Row>
 </section>

 {/* Contact Information */}
 <section className="mb-4">
 <h5 className="text-primary mb-3">
 Contact Information
 </h5>
 <Row className="g-3">
 <Col md={6}>
 <Form.Group>
 <Form.Label>Phone Number</Form.Label>
 <Form.Control
 type="tel"
 placeholder="(123) 456-7890"
 value={PhoneNumber}
 onChange={(e)=>setPhoneNumber(e.target.value)}
 />
 </Form.Group>
 </Col>

 <Col md={6}>
 <Form.Group>
 <Form.Label>Store Address</Form.Label>
 <Form.Control
 type="text"
 placeholder="Enter store address"
 value={storeAddress}
 onChange={(e)=>setstoreAddress(e.target.value)}
 />
 </Form.Group>
 </Col>
 </Row>
 </section>

 {/* Operating Hours */}
 <section className="mb-4">
 <h5 className="text-primary mb-3">
 Operating Hours
 </h5>
 <Row className="g-3">
 <Col md={6}>
 <Form.Group>
 <Form.Label>Opening Time</Form.Label>
 <Form.Control type="time" />
 </Form.Group>
 </Col>

 <Col md={6}>
 <Form.Group>
 <Form.Label>Closing Time</Form.Label>
 <Form.Control type="time" />
 </Form.Group>
 </Col>
 </Row>
 </section>

 {/* Store Description */}
 <section className="mb-4">
 <Form.Group>
 <Form.Label>Store Description</Form.Label>
 <Form.Control
 as="textarea"
 rows={3}
 placeholder="Describe your store..."
 />
 </Form.Group>
 </section>

 {/* Store Status */}
 <Form.Group className="mb-4">
 <Form.Check
 type="switch"
 id="store-status"
 label="Store Active"
 />
 </Form.Group>

 {/* Submit Button */}
 <div className="d-grid gap-2">
 <button
 type="submit"
 className="btn btn-success btn-lg"
 onClick={()=>CreateStoreCall()}
 >
 Save Store Information
 </button>
 </div>
 </Form>
 </Card.Body>
 </Card>
 </Container>
 </div>
 {/*Store List */}
 <div
 className="tab-pane fade "
 id="store-list-tab-pane"
 role="tabpanel"
 aria-labelledby="store-list-tab"
 >
 <div className="w-100">
 <div className="bg-success p-3">
 <div className="d-flex justify-content-between align-items-center">
 <div className="d-flex align-items-center">
 <FaStore className="text-white me-2" size={24} />
 <h3 className="text-white mb-0">Added Stores</h3>
 </div>
 <div className="bg-white px-3 py-1 rounded">
 Total Stores: {stores.length}
 </div>
 </div>
 </div>

 <div className="p-3">
 {stores?.map((store) => (
 <div
 key={store.id}
 className="bg-white border rounded mb-3 w-100"
 >
 <div className="row p-3 m-0 align-items-center">
 

 <div className="col-lg-8 col-md-6 col-sm-12">
 <div className="d-flex flex-wrap gap-2 align-items-center mb-2">
 <h5 className="mb-0">{store.name}</h5>
 <span
 className={`badge ${
 store.status === "active"
 ? "bg-success"
 : "bg-secondary"
 }`}
 >
 {store.status === "active"
 ? "Active"
 : "Inactive"}
 </span>
 <span className="badge bg-primary">
 {store.cuisine}
 </span>
 </div>

 <div className="text-muted">
 <div className="mb-1">
 <FaMapMarkerAlt className="me-2" />
 <small>{store.address}</small>
 </div>
 <div className="mb-1">
 <FaPhoneAlt className="me-2" />
 <small>{store.phone_number}</small>
 </div>
 <div>
 <FaClock className="me-2" />
 <small>{store.created_at}</small>
 </div>
 </div>
 </div>

 <div className="col-lg-2 col-md-3 col-sm-12 mt-3 mt-md-0">
 <div className="d-flex flex-column gap-2">
 {store.status === "active" ? (
 <button
 disabled
 className="btn btn-outline-success btn-sm w-100"
 >
 Active
 </button>
 ) : (
 <button
 disabled
 className="btn btn-outline-danger btn-sm w-100"
 >
 Deactivate
 </button>
 )}

 <button
 className="btn btn-outline-secondary btn-sm w-100"
 type="button"
 data-bs-toggle="modal"
 data-bs-target="#exampleModal"
 onClick={() =>
 FetchStoreDetailtCall(
 store.external_store_id
 )
 }
 >
 View Details
 </button>
 </div>
 </div>
 </div>
 </div>
 ))}
 </div>
 </div>
 </div>
 {/*Delivery */}
 <div
 className="tab-pane fade"
 id="delivery-tab-pane"
 role="tabpanel"
 aria-labelledby="delivery-list-tab"
 >
 <div className="w-100">
 <div className="bg-success p-3">
 <div className="d-flex justify-content-between align-items-center">
 <div className="d-flex align-items-center">
 <FaStore className="text-white me-2" size={24} />
 <h3 className="text-white mb-0">
 DoorDash Deliveries
 </h3>
 </div>
 <div className="bg-white px-3 py-1 rounded">
 Total Deliveries: N/A
 </div>
 </div>
 </div>

 <div className="p-3">
 <div className="">
 <div className="card-body">
 <div className="table-responsive">
 <table className="table table-hover">
 <thead>
 <tr>
 <th>Order ID</th>
 <th>Store Name</th>
 <th>Customer</th>
 <th>Delivery Address</th>
 <th>Order Time</th>
 <th>Status</th>
 <th>Amount</th>
 <th>Actions</th>
 </tr>
 </thead>
 <tbody>
 {[
 {
 id: "NA",
 store: "NA",
 customer: "NA",
 address: "NA",
 time: "NA",
 status: "NA",
 amount: "NA",
 },
 
 ].map((delivery) => (
 <tr key={delivery.id}>
 <td>{delivery.id}</td>
 <td>{delivery.store}</td>
 <td>{delivery.customer}</td>
 <td>{delivery.address}</td>
 <td>{delivery.time}</td>
 <td>
 <span
 className={`badge ${
 delivery.status === "Delivered"
 ? "bg-success"
 : delivery.status === "In Transit"
 ? "bg-primary"
 : "bg-warning"
 }`}
 >
 {delivery.status}
 </span>
 </td>
 <td>{delivery.amount}</td>
 <td>
 <div className="d-flex gap-2">
 <button
 className="btn btn-sm btn-outline-primary"
 title="View Details"
 >
 <FaEye size={14} />
 </button>
 <button
 className="btn btn-sm btn-outline-secondary"
 title="Track Order"
 >
 <FaRoute size={14} />
 </button>
 </div>
 </td>
 </tr>
 ))}
 </tbody>
 </table>
 </div>

 <div className="d-flex justify-content-between align-items-center mt-3">
 <div>
 <span className="text-muted">
 Showing 1 to 5 of 15 entries
 </span>
 </div>
 <nav>
 <ul className="pagination mb-0">
 <li className="page-item disabled">
 <a
 className="page-link"
 href="#"
 tabIndex="-1"
 >
 Previous
 </a>
 </li>
 <li className="page-item active">
 <a className="page-link" href="#">
 1
 </a>
 </li>
 <li className="page-item">
 <a className="page-link" href="#">
 2
 </a>
 </li>
 <li className="page-item">
 <a className="page-link" href="#">
 3
 </a>
 </li>
 <li className="page-item">
 <a className="page-link" href="#">
 Next
 </a>
 </li>
 </ul>
 </nav>
 </div>
 </div>
 </div>
 </div>
 </div>
 </div>
 </div>
 </>
 ) : (
 <div>No Doordash found.</div>
 )}
 </>
 )}
 </div>
 {/* {Modal for View details} */}
 <div
 class="modal fade"
 id="exampleModal"
 tabindex="-1"
 aria-labelledby="exampleModalLabel"
 aria-hidden="true"
 >
 <div class="modal-dialog">
 <div class="modal-content">
 <div class="modal-header">
 <h1 class="modal-title fs-5" id="exampleModalLabel">
 {StoreDetails.name}
 </h1>
 <button
 type="button"
 class="btn-close"
 data-bs-dismiss="modal"
 aria-label="Close"
 ></button>
 </div>
 <div class="modal-body">
 <div className="d-flex">
 <p>
 <b>Name:</b>
 {StoreDetails.name}
 </p>
 </div>
 <div className="d-flex">
 <p>
 <b>Store ID:</b>
 {StoreDetails.external_store_id}
 </p>
 </div>

 <div className="d-flex">
 <p>
 <b>Phone Number:</b>
 {StoreDetails.phone_number}
 </p>
 </div>
 <div className="d-flex">
 <p>
 <b>Address:</b>
 {StoreDetails.address}
 </p>
 </div>
 <div className="d-flex">
 <p>
 <b>Status:</b>
 {StoreDetails.status}
 </p>
 </div>
 <div className="d-flex">
 <p>
 <b>Created At:</b>
 {StoreDetails.created_at}
 </p>
 </div>
 <div className="d-flex">
 <p>
 <b>Last Updated</b>
 {StoreDetails.last_updated_at}
 </p>
 </div>
 </div>
 <div class="modal-footer">
 <button
 type="button"
 class="btn btn-secondary"
 data-bs-dismiss="modal"
 >
 Close
 </button>
 </div>
 </div>
 </div>
 </div>
 </div>
 );
};
export default Doordash;