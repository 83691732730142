import React, { useContext, useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { MdEdit } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Container, Row, Col, Form, Button, Table, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import "./AdminDashboard.css";
const Allblogs = () => {
  const [blogdata, setBlogData] = useState([]);

  const Getproductblogs = async () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          product_name: "Anlook",
          type: "edit",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetProductBlogs`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setBlogData(data.data || []);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
    }
  };

  useEffect(() => {
    Getproductblogs();
  }, []);

  return (
    <div className="marginRight p-4">
      {" "}
      <div className="m-3">
        <h4>Blog Posts</h4>
        <Table
          responsive
          className="table responsive-table table-bordered table-striped"
        >
          <thead>
            <tr>
              <th
                className="text-center"
                style={{ background: "#A1D6B2", color: "#16325B" }}
              >
                ID
              </th>
              <th
                className="text-center"
                style={{
                  background: "#A1D6B2",
                  color: "#16325B",
                  width: "20vw",
                }}
              >
                Title
              </th>
              <th
                className="text-center"
                style={{
                  background: "#A1D6B2",
                  color: "#16325B",
                  width: "20vw",
                }}
              >
                Excerpt
              </th>
              <th
                className="text-center"
                style={{ background: "#A1D6B2", color: "#16325B" }}
              >
                Author
              </th>
              <th
                className="text-center"
                style={{ background: "#A1D6B2", color: "#16325B" }}
              >
                Created At
              </th>
              <th
                className="text-center"
                style={{ background: "#A1D6B2", color: "#16325B" }}
              >
                Status
              </th>
              <th
                className="text-center"
                style={{ background: "#A1D6B2", color: "#16325B" }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {blogdata.length === 0 ? (
              <tr>
                <td colSpan="7" className="text-center">
                  No data found
                </td>
              </tr>
            ) : (
              blogdata.map((blog) => (
                <tr key={blog.post_id}>
                  <td className="text-center align-middle">{blog.post_id}</td>
                  <td className="text-center align-middle font-weight-bold">
                    {blog.post_title}
                  </td>
                  <td className="text-center align-middle">
                    {blog.post_excerpt.split(" ").slice(0, 15).join(" ")}...
                  </td>
                  <td className="text-center align-middle">
                    {blog.post_author}
                  </td>
                  <td className="text-center align-middle">
                    {new Date(blog.created_at).toLocaleDateString()}
                  </td>
                  <td className="text-center align-middle">{blog.status}</td>
                  <td className="text-center align-middle">
                    <div className="d-flex justify-content-center gap-3">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}
                      >
                        <Link
                          to="/admin/Editblogs"
                          onClick={() => {
                            sessionStorage.setItem(
                              "Editblogid",
                              JSON.stringify(blog)
                            );
                          }}
                        >
                          <MdEdit
                            style={{ fontSize: "1.3rem", color: "green" }}
                          />
                        </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}
                      >
                        <FaTrash
                          style={{
                            fontSize: "1rem",
                            color: "red",
                            cursor: "pointer",
                          }}
                        />
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Publish</Tooltip>}
                      >
                        <IoIosSend
                          style={{
                            fontSize: "1.3rem",
                            color: "#7695FF",
                            cursor: "pointer",
                          }}
                        />
                      </OverlayTrigger>
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Allblogs;
