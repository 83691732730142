import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, useLocation  } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Blogs from                  './components/Landing_Page/Blogs';
import Post from                  './components/Landing_Page/Post';
import Dashboard from            './components/Dashboard/Dashboard';
import CreateTemplate from      './components/Whatsapp/Create_Template';
import Chats from              './components/Whatsapp/Chats';
import CreateCampaign from    './components/Whatsapp/Create Campaign';
import Order from            './components/Order/Order'         ;
import PlansAndPricing from './components/Profile/Plans&Pricing';
import UserManagement from './components/Profile/UserManagement';
import SupportCenter from './components/Profile/SupportCenter'  ;
import DLTTamplate from  './components/Whatsapp/TemplateStatus' ;
import ProfileSetting from './components/Profile/ProfileSetting'           ;
import Campaign from './components/Whatsapp/Campaign';
import IdentityPage from './components/IdentityPage';
import NavigationBar from './components/DesktopNav';
import Pricing from './components/Landing_Page/Pricing';
import Contact from './components/Landing_Page/Landing_Contact';
import Privacy from './components/Landing_Page/Landing_Privacy';
import TAndC from './components/Landing_Page/Landing_T&C';
import CampaignDetails from './components/Whatsapp/campaign_details';
import CampaignView from './components/Whatsapp/campaign_view';
import TemplateView from './components/Whatsapp/template_view';
import TemplateEdit from './components/Whatsapp/Template_Edit';
import Cancellation from './components/Landing_Page/Landing_Cancellation';
import ChatBot from './components/Whatsapp/Chat_Bot';
import Catalogue from './components/Order/Catalogue';
import CatalogueTable from './components/Order/CatalogueTable';
import Logs from './components/Whatsapp/Logs';
import LandingAbout from './components/Landing_Page/Landing_AboutUs';
import Home from './components/Landing_Page/Home_Page/Home_Page';
import ContactBook from './components/Whatsapp/Contact_Book';
import WelcomePage from './components/Profile/WelcomePage';
import Delivery from './components/Order/Delivery ';
import ChatBotMain from './components/Whatsapp/ChatBotMain';
import LeadDetails from './components/Analytics/LeadDetails';
import LeadManagement from './components/Analytics/Lead_Management';
import TemplateLibrary from './components/Whatsapp/TemplateLibrary';
import Analytics from './components/Analytics/Analytics';
import PayOut from './components/Order/PayOut';
import OrderSummary from './components/Order/OrderSummary';
import ExpiredPlan from './components/ExpiredPlan';
import MyLanding from './components/Main_Landing_Page/Main_Landing_Page';
import Payment from './components/Order/Payment';
// import OrderAlert from './components/Contact Book/OrderAlert';
import FaceBookFeed from './components/Feed/FaceBookFeed';
import SubscriptionBanner from './components/SubscriptionBanner';
import Setup from './components/Profile/Setup';
// import TrackingPage from './TrackingPage';
import "./App.css";
// Admin imports here
import TopNav from "./components/Admin/NavbarAdmin";
import SideNav from "./components/Admin/AdminSideNav";
import ClientAnalyticsDashboard from "./components/Admin/ClientDashboard";
import EmployeeSectionCards from "./components/Admin/AdminEmployee";
import StatisticsDashboard from "./components/Admin/AdminStatic";
import PayoutSection from "./components/Admin/AdminPayoutSection";
import Login from "./components/Admin/AdminLogin";
import Clients from "./components/Admin/AdminClients";
import AdminOrders from "./components/Admin/AdminOrders";
import AdminBlog from "./components/Admin/Blog";
import AdminAllBlogs from "./components/Admin/Allblog";
import AdminEditBlogs from "./components/Admin/Editblog";
import AdminLog from "./components/Admin/AdminLog";
import { initGA, logPageView } from "./analytics";
import BlockUser from "./components/Order/BlockUser";
import WhatsAppLogs from "./components/Admin/AdminWhatsApp";
import ChatComponent from "./components/Admin/AdminChat";
import AllPost from './components/Feed/AllPost';
import Friends from './components/Feed/Friends';
import FbTamplate from './components/Feed/FB_Tamplate';
import FbCampaign from './components/Feed/FB_Campaign';
import FbTamplateStatus from './components/Feed/FB_Tamplate_Status';
import FbCampaignStatus from './components/Feed/FB_Campaign_Status';
import FbLogs from './components/Feed/FB_Logs';
import FbTemplateLibrary from './components/Feed/FB_Tamplate_Librery';
import FbChatBot from './components/Feed/FB_Chatbot';
import Dining from './components/Order/Dining';
import AdminAuthors from './components/Admin/AdminAuthors';
import Author from './components/Landing_Page/Author';
import DoorDash from './components/Order/DoorDash/DoorDash';
import BuyPlan from './components/Main_Landing_Page/Buy_Plan';
import GetStarted from './components/Main_Landing_Page/GetStarted';
const TrackPageView = () => {
  const location = useLocation();
  useEffect(() => {
    logPageView(location.pathname + location.search);
  }, [location]);
  return null;
};

// let beepSound = new Audio(beepSoundmp3); 
// beepSound.loop = true; 
// beepSound.load(); 

const App = () => {
  const [user, setUser] = useState(false);
  const [power, setPower] = useState("");
  const [userID, setUserID] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const handleAdminLogout = () => {
    setIsAdmin(false);
    sessionStorage.removeItem("AdminUser");
    window.location.href = "https://www.anlook.com/";
  };
  const [Admin, setAdmin] = useState();
  useEffect(() => {
     const checkAdminUser = () => {
       const storedUser = sessionStorage.getItem("AdminUser");
       if (storedUser) {
         setIsAdmin(true);
         setAdmin(storedUser);
       } else {
         setIsAdmin(false);
       }
     };
     checkAdminUser();
     window.addEventListener("storage", checkAdminUser);
     return () => {
       window.removeEventListener("storage", checkAdminUser);
     };
   }, []);
  const handleLogout = () => {
    setUser(false);
    sessionStorage.removeItem('user'); 
     window.location.href = 'https://www.anlook.com/';
  };
   useEffect(() => {
      const storedUser = sessionStorage.getItem('user');
      if (storedUser) {
        setUser(true);
        const parsedUser = JSON.parse(storedUser);
        setUserID(parsedUser.userid);
        setProfiles(parsedUser);
      } else {
        // login();
        <Home onLoginClick={login} />
      }
  }, []);
  const login = () => {
    const productId = "1";
    const callbackUrl = "https://www.anlook.com/identity";
    // const callbackUrl = `${window.location.origin}/identity`;
    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}&product_id=${productId}`;
    window.location.href = redirectUrl; 
  };
  const signUp = () => {
    const productId = "1";
    const callbackUrl = "https://www.anlook.com/identity";
    // const callbackUrl = `${window.location.origin}/identity`;
    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}&product_id=${productId}&intent=signup`;
    window.location.href = redirectUrl; 
  };
  useEffect(() => {
    const storedPower = sessionStorage.getItem('power');
    if (storedPower) {
      setPower(storedPower);
    }
  }, []);
// Initialize Google Analytics
  useEffect(() => {
    initGA();
  }, []);
const breadcrumbData = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://www.anlook.com/"
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": "Identity",
        "item": "https://www.anlook.com/identity"
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": "Blogs",
        "item": "https://www.anlook.com/blogs"
      },
      {
        "@type": "ListItem",
        "position": 4,
        "name": "Post",
        "item": "https://www.anlook.com/post"
      },
      {
        "@type": "ListItem",
        "position": 5,
        "name": "Pricing",
        "item": "https://www.anlook.com/pricing"
      },
      {
        "@type": "ListItem",
        "position": 6,
        "name": "Contact",
        "item": "https://www.anlook.com/contact"
      },
      {
        "@type": "ListItem",
        "position": 7,
        "name": "Privacy",
        "item": "https://www.anlook.com/PrivacyPolicy"
      },
      {
        "@type": "ListItem",
        "position": 8,
        "name": "Terms & Conditions",
        "item": "https://www.anlook.com/terms-and-conditions"
      },
      {
        "@type": "ListItem",
        "position": 9,
        "name": "Cancellation",
        "item": "https://www.anlook.com/cancellation"
      },
      {
        "@type": "ListItem",
        "position": 10,
        "name": "About Us",
        "item": "https://www.anlook.com/about-us"
      },
 
    
    ]
  };
  const [country, setCountry] = useState("");
  useEffect(() => {
    const storedCountry = sessionStorage.getItem("userCountry");
    if (!storedCountry) {  
      // If no country in session storage, fetch location from API
      const fetchLocation = async () => {
        try {
          const response = await axios.get(
            `https://api.ipgeolocation.io/ipgeo?apiKey=1e4cf2d0a0ee4ebe92e18c3e52000ebf`
          );
          const userCountry = response.data.country_code2; 
          setCountry(userCountry);
          sessionStorage.setItem("userCountry", userCountry); 
        } catch (error) {
          console.error("Error fetching geolocation data:", error);
          setCountry("Other");
        }
      };
      fetchLocation();
    } else { 
      // If country is stored in session storage, use it  
      setCountry(storedCountry);
    }
  }, []);
  // Function to handle manual country change from dropdown
  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry);
    sessionStorage.setItem("userCountry", selectedCountry);
  };
  return (
    <Router>
        {/*    
          <div>
        {country === "IN" && <p>You are in India!</p>}
        {country === "US" && <p>You are in the United States!</p>}
        {country === "Other" && <p>You are in another country!</p>}
        </div>    
        */}
      {/* <TrackingPage/> */}
  { user && (
    <>
      <SubscriptionBanner userID={userID}  />
    </>
  )}
          <TrackPageView />
    {/*  {user && <OrderAlert userID={userID} />} */}
    {/* <div className="selectable-text" style={{ display: 'flex' }}> */}
     <div>
      {/* Admin */}
    <Routes>
          {" "}
          <Route path="/admin" element={<Login />} />
           </Routes>
           {isAdmin && (
          <>
            <TopNav />
            <SideNav Admin={Admin} logout={handleAdminLogout} />
            <Routes>
              <Route path="/admin/home" element={<StatisticsDashboard Admin={Admin} />} />
              <Route path="/admin/Clients" element={<Clients  Admin={Admin}/>} />
              <Route path="/admin/Logs" element={<AdminLog   Admin={Admin}/>} />
              <Route
                path="/admin/ClientDashboard/:clientId"
                element={<ClientAnalyticsDashboard  Admin={Admin}/>}
              />
              <Route
                path="/admin/employee"
                element={<EmployeeSectionCards Admin={Admin} />}
              />
              {/* <Route path="/admin/statics" element={<StatisticsDashboard />} /> */}
              <Route path="/admin/payout" element={<PayoutSection  Admin={Admin}/>} />
              <Route path="/admin/orders" element={<AdminOrders  Admin={Admin}/>} />
              <Route path="/admin/Blogs" element={<AdminBlog  Admin={Admin}/>} />
              <Route path="/admin/Allblogs" element={<AdminAllBlogs  Admin={Admin}/>} />
              <Route path="/admin/Editblogs" element={<AdminEditBlogs  Admin={Admin}/>} />
              <Route path="/admin/WhatsApp" element={<WhatsAppLogs  Admin={Admin}/>} />
              <Route path="/admin/AdminChat" element={<ChatComponent  Admin={Admin}/>} />
              <Route path="/admin/Authors" element={<AdminAuthors Admin={Admin}/>} />
            </Routes>
          </>
        )}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(breadcrumbData)}          
        </script>
      </Helmet>
    <Routes>
      {!user &&
      <>    
    <Route path="/get" element={ <MyLanding  onLoginClick={login}  />} />
    <Route path="/get/BuyPlan" element={ <BuyPlan  onLoginClick={login}  />} />
    <Route path="/get/GetStarted" element={ <GetStarted  onLoginClick={login}  />} />
    <Route path="/" element={ <Home onSignUpClick={signUp}  onLoginClick={login}  country= {country} handleCountryChange={handleCountryChange}/>} />
    <Route path="/identity" element={<IdentityPage power={power} setUser={setUser} setPower={setPower}  />} /> 
    <Route path="/blogs" element={<Blogs onSignUpClick={signUp} onLoginClick={login} country={country} handleCountryChange={handleCountryChange}  />} /> 
    <Route path="/blog/:post_slug" element={< Post onSignUpClick={signUp} onLoginClick={login} country={country} handleCountryChange={handleCountryChange} />} /> 
    <Route path="/pricing" element={< Pricing onSignUpClick={signUp} onLoginClick={login} country={country} handleCountryChange={handleCountryChange} />} /> 
    <Route path="/author/:Author" element={<Author onSignUpClick={signUp} onLoginClick={login} country={country} handleCountryChange={handleCountryChange} />} />
    <Route path="/contact" element={< Contact onSignUpClick={signUp} onLoginClick={login} country={country} handleCountryChange={handleCountryChange} />} /> 
    <Route path="/PrivacyPolicy" element={< Privacy onSignUpClick={signUp} onLoginClick={login} country={country} handleCountryChange={handleCountryChange} />} /> 
    <Route path="/T&C" element={< TAndC onSignUpClick={signUp} onLoginClick={login} country={country} handleCountryChange={handleCountryChange} />} /> 
    <Route path="/Cancellation" element={<Cancellation onSignUpClick={signUp} onLoginClick={login} country={country} handleCountryChange={handleCountryChange}  />} />
    <Route path="/AboutUs" element={<LandingAbout onSignUpClick={signUp} onLoginClick={login} country={country} handleCountryChange={handleCountryChange}  />} />
      </>
      }
    </Routes>
      {user ? (
     <>
    <NavigationBar setUser={setUser} handleLogout={handleLogout} userID={userID} />
     <div style={{ flex: 1, paddingLeft: '145px',}}>
     <Routes>      
              {power === '1' ? (
                <>
                  <Route path="/Dashboard" element={<Dashboard userID={userID} />} />
                  <Route path="/WhatsApp/Create-Template"  element={<CreateTemplate userID={userID} />} />
                  <Route path="/WhatsApp" element={<Chats userID={userID} />} />
                  <Route path="/WhatsApp/ContactBook" element={<ContactBook userID={userID} />} />
                  <Route path="/WhatsApp/ChatBot" element={<ChatBotMain userID={userID} />} />
                  <Route path="/WhatsApp/ChatBotStop" element={<ChatBot userID={userID} />} />
                  <Route path="/WhatsApp/Campaign" element={<CreateCampaign userID={userID} />} />
                  <Route path="/WhatsApp/Template" element={<DLTTamplate userID={userID} />} />
                  <Route path="/WhatsApp/Manage" element={<Campaign userID={userID} />} />
                  <Route path="/WhatsApp/Logs" element={<Logs userID={userID} />} />
                  <Route path="/WhatsApp/TemplateLibrary" element={<TemplateLibrary userID={userID} />} />
                  <Route path="/facebook/feed" element={<FaceBookFeed userID={userID} />} />
                  <Route path="/facebook/post" element={<AllPost userID={userID} />} />
                  <Route path="/facebook" element={<Friends userID={userID} />} />
                  <Route path="/facebook/Create-Template" element={<FbTamplate userID={userID} />} />
                  <Route path="/facebook/Campaign" element={<FbCampaign userID={userID} />} />
                  <Route path="/facebook/Template" element={<FbTamplateStatus userID={userID} />} />
                  <Route path="/facebook/Manage" element={<FbCampaignStatus userID={userID} />} />
                  <Route path="/facebook/Logs" element={<FbLogs userID={userID} />} />
                  <Route path="/facebook/TemplateLibrary" element={<FbTemplateLibrary userID={userID} />} />
                  <Route path="/facebook/ChatBot" element={<FbChatBot userID={userID} />} />
                  <Route path="/Orders"  element={<Order userID={userID}  />} />
                  <Route path="/Orders/Dinning" element={<Dining userID={userID} />} />
                  <Route path="/Orders/DoorDash" element={<DoorDash userID={userID} />} />
                  <Route path="/Orders/AddCatalogue" element={<Catalogue userID={userID} />} />
                  <Route path="/Orders/Catalogue" element={<CatalogueTable userID={userID} />} />
                  <Route path="/Orders/Delivery" element={<Delivery userID={userID} />} />
                  <Route path="/Orders/BlockUser" element={<BlockUser userID={userID} />} />
                  <Route path="/Orders/Payments" element={<Payment userID={userID} />} />
                  <Route path="/Orders/Payouts" element={<PayOut userID={userID} />} />
                  <Route path="/Orders/OrderSummary" element={<OrderSummary userID={userID} />} />
                  <Route path="/Analytics" element={<Analytics userID={userID} />} />
                  <Route path="/Analytics/Leads" element={<LeadManagement userID={userID} />} />
                  <Route path="/Analytics/LeadDetails" element={<LeadDetails userID={userID} />} />
                  <Route path="/Profile" element={<ProfileSetting profiles={profiles} power={power} />} />
                  <Route path="/Profile/Plans" element={<PlansAndPricing userID={userID} country= {country} handleCountryChange={handleCountryChange} />} />
                  <Route path="/Profile/Setup" element={<Setup userID={userID} />} />
                  <Route path="/Profile/UserManagement" element={<UserManagement  userID={userID} />} />
                  <Route path="/Profile/SupportCenter" element={<SupportCenter userID={userID}/>} />
                  <Route path="/CampaignDetails" element={<CampaignDetails  userID={userID}/>} />
                  <Route path="/CampaignView" element={<CampaignView userID={userID} />} />
                  <Route path="/TemplateView" element={<TemplateView userID={userID}/>} />
                  <Route path="/WhatsApp/Template/TemplateEdit" element={<TemplateEdit userID={userID}/>} />                
                  <Route path="/Profile/WelcomePage" element={<WelcomePage profiles={profiles} />} />
                  <Route path="/ExpiredPlan" element={<ExpiredPlan userID={userID} profiles={profiles} />} />
                </>
              ) : (
                <>
                 {/* <Navigate to="/Profile" replace /> */}
                <Route path="/ExpiredPlan" element={<ExpiredPlan userID={userID} profiles={profiles} />} />
                <Route path="/Profile" element={<ProfileSetting userID={userID} />} />
                <Route path="/Profile/Plans" element={<PlansAndPricing userID={userID} />} />
                <Route path="/Profile/Setup" element={<Setup userID={userID} />} />
                <Route path="/Profile/WelcomePage" element={<WelcomePage userID={userID} profiles={profiles} />} />
                <Route path="/Profile/UserManagement" element={<UserManagement userID={userID} />} />
                <Route path="/Profile/Technical_Setting" element={<PlansAndPricing userID={userID} />} />
                </>
              )}
            </Routes>
        </div>
        </>  
      ) : (
        <Routes>
          {/* <Route path="/" element={<Login login={login}  />} /> */}
        </Routes>
      )}
    </div>
  </Router>
  );
};
export default App;