import React from "react";
import LandingNav from "./Landing_Nav";
import LandingFooter from "./Landing_Footer";
import { Helmet } from 'react-helmet';

const Cancellation = ({onSignUpClick, onLoginClick }) => {

  return (
    <>



<Helmet>
        <title>Anlook Cancellation Policy - Hassle-Free Service Management</title>
        <meta name="description" content="Understand Anlook's cancellation policy for our WhatsApp automation tool. Learn about the process, conditions, and any applicable fees for cancelling your subscription. Manage your service with ease." />
        <meta name="keywords" content="Anlook cancellation policy, service cancellation, subscription management, cancellation process, cancellation terms, Anlook subscription, service termination, cancellation fees, WhatsApp automation" />

        {/* Professional schema markup */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Cancellation Policy",
              "url": "https://www.anlook.com/Cancellation",
              "description": "Understand Anlook's cancellation policy for our WhatsApp automation tool. Learn about the process, conditions, and any applicable fees for cancelling your subscription.",
              "mainEntity": {
                "@type": "Organization",
                "name": "Anlook",
                "url": "https://www.anlook.com",
                "logo": "https://bumppy.in/anlook/api/v1/uploads/utils/anlook_logo.webp"
                
              },
              "mainContentOfPage": {
                "@type": "WebPageElement",
                "headline": "Anlook's Return/ Refund/ Cancellation Policy",
                "text": "We only offer digital products, so refunds, cancellations, or returns are not possible after an order has been placed. You can cancel a subscription anytime with no charges deducted after cancellation. For other questions, email us at support@anlook.com."
              }
            }
          `}
        </script>
      </Helmet>



      <div className=" landing-body-bg">
        <LandingNav onSignUpClick={onSignUpClick} onLoginClick={onLoginClick} />
        <div className="padding-5-rem " style={{height:"100vh"}}>
          <div className="row">
            <div className="col-12">
              <div className=" border-radius-20 pb-5 ">
                <div className="text-2-2  py-4 pb-5 ">Return/ Refund/ Cancellation Policy</div>
                <div className="">
                  <p>
                  We only offer digital products, so refunds, cancellations, or returns are not possible after an order has been placed. If you have a continuing subscription, you can cancel anytime, and no charges will be deducted after you cancel the subscription or membership. However, any charges deducted before cancellation of membership cannot be refunded.
                  </p>
                  <p className="text-3-2">
                  For any other questions regarding cancellation or refunds, please send us an email at
                   <a href="https://mail.google.com/mail/?view=cm&fs=1&to=support@anlook.com" target="_blank" rel="noopener noreferrer">
   {" "} support@anlook.com
  </a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LandingFooter />
      </div>
    </>
  );
};
export default Cancellation;
