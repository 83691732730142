import React, { useState, useEffect, useContext } from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import Navbar from "./Landing_Nav";
import Footer from "./Landing_Footer";

const Author = () => {
  const productURLMapping = {
    Anlook: "https://anlook.com/blog",
    BillPunch: "https://billpunch.com/blog",
  };
  const [Blogdata, setBlogdata] = useState([]);
  const [authorData, setAuthorData] = useState(null);

  const GetBlogsByAuthors = async (authorSlug) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ author_slug: authorSlug }),
        redirect: "follow",
      };

      const res = await fetch(
        `https://api.goseo.in/api/Seo/GetBlogsByAuthors`,
        requestOptions
      );
      const data = await res.json();
      console.log("GetBlogsByAuthors response:", data);

      if (data.status === "0") {
        const authorInfo = data.data[0];
        setAuthorData({
          name: authorInfo.author_name,
          image: authorInfo.author_image,
        });
        const filteredPosts = authorInfo.posts.filter(
          (post) => post.product_name === "Anlook"
        );
        setBlogdata(filteredPosts);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching blogs:", error);
    } finally {
    }
  };
 
  useEffect(() => {
    const author = localStorage.getItem("author");
    console.log("SessionStorage Author (raw):", author);

    if (author) {
      const parsedAuthor = JSON.parse(author);
      console.log("Author from sessionStorage (parsed):", parsedAuthor);
      setAuthorData(parsedAuthor);
      GetBlogsByAuthors(parsedAuthor.slug);
    } else {
      console.warn("No author found in sessionStorage.");
    }
  }, []);

  const renderPlaceholders = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <Col key={`placeholder-${index}`} className="p-2">
        <Card className="h-100 p-0" style={{ visibility: "hidden" }}>
          <Card.Body />
        </Card>
      </Col>
    ));
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-10 mb-5">
            <div>
              <div className="d-flex justify-content-center">
                <div className="col-12 d-flex justify-content-center mb-4">
                  <div className="card p-4 shadow-none" style={{background:"white", border:".2rem solid #E4E0E1"}}>
                    {authorData ? (
                      <>
                        <div className="d-flex justify-content-center">
                          <div 
                            style={{
                              width: "200px",
                              height: "200px",
                              borderRadius: "50%",
                              overflow: "hidden",
                              position: "relative"
                            }}
                          >
                            <img
                              src={authorData.image}
                              alt={authorData.name}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)"
                              }}
                            />
                          </div>
                        </div>
                        <h4 className="d-flex mt-3 justify-content-center">
                          {authorData.name}
                        </h4>
                      </>
                    ) : (
                      <p>Loading author data...</p>
                    )}
                  </div>
                </div>
              </div>
              {Blogdata.length > 0 ? (
                <Row className="d-flex flex-wrap justify-content-start mx-n3">
                  {Blogdata.map((post) => (
                    <div
                      key={post.post_id}
                      style={{
                        flex: '0 0 25%',
                        maxWidth: '25%',
                        padding: '1rem',
                      }}
                    >
                      <Card 
                        className="h-100 w-100 p-0 shadow hover-shadow transition-shadow"
                        style={{ 
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                        <div 
                          style={{
                            position: 'relative',
                            paddingTop: '56.25%', // 16:9 aspect ratio
                            width: '100%',
                            overflow: 'hidden'
                          }}
                        >
                          <Card.Img
                            variant="top"
                            src={post.post_featured_image}
                            alt={post.post_featured_image_alt}
                            style={{
                              position: 'absolute',
                              top: '0',
                              left: '0',
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover'
                            }}
                          />
                        </div>
                        <Card.Body 
                          className="p-3" 
                          style={{
                            flex: '1',
                            display: 'flex',
                            flexDirection: 'column'
                          }}
                        >
                          <p
                            className="mb-2"
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 2,
                              color: "#000",
                              fontSize: "1.2rem",
                              fontWeight: "600",
                              lineHeight: "1.4",
                              marginBottom: '0.75rem'
                            }}
                          >
                            {post.post_title}
                          </p>
                          <Card.Text
                            style={{
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              overflow: "hidden",
                              WebkitLineClamp: 3,
                              fontSize: "1rem",
                              lineHeight: "1.5",
                              flex: '1'
                            }}
                          >
                            {post.post_excerpt}
                          </Card.Text>
                          <Card.Link
                            className="text-primary font-weight-bold mt-2"
                            target="_blank"
                            href={
                              productURLMapping[post.product_name]
                                ? `${productURLMapping[post.product_name]}/${post.post_slug}`
                                : `/blog/${post.post_slug}`
                            }
                            style={{
                              display: 'block',
                              marginTop: 'auto'
                            }}
                          >
                            Read More →
                          </Card.Link>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </Row>
              ) : (
                <p className="text-center mt-4">No blogs found for this author.</p>
              )}
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Author;