import React, { useState, useEffect } from "react";
import { Nav, Tab, Form, FormControl } from "react-bootstrap";
import { Container, Row, Col, Button, Table } from "react-bootstrap";

import axios from "axios";
import NoIndex from "../NoIndex";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import eye from "../../images/EYE.png";
import trash from "../../images/Delete.svg";
import Edit from "../../images/message-edit.png";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import Pagination from "../Whatsapp/Pagination";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import pause from "../../images/Pause.png";
import Play from "../../images/Play.png";
import "./AdminDashboard.css";

const WhatsAppLogs = ({ Admin }) => {
  const [activeKey, setActiveKey] = useState("campaign");

  // WhatsApp Logs Component
  const WhatsAppLogs = () => {
    const [Campaign, setCampaign] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Show 10 items per page
    const [searchTerm, setSearchTerm] = useState("");
    const [deleteState, setdeleteState] = useState("");
    const [ExcuteDelete, setExcuteDelete] = useState("");
    const [ModalData, setModalData] = useState("");
    const [ErrorDelete, setErrorDelete] = useState();
  
    const handleClick = (type) => {
      if (type === "prev") {
        setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
      } else if (type === "next") {
        setCurrentPage(currentPage === totalPages ? totalPages : currentPage + 1);
      }
    };
  
    const handlePageClick = (pageNumber) => {
      if (
        pageNumber !== currentPage &&
        pageNumber >= 1 &&
        pageNumber <= totalPages
      ) {
        setCurrentPage(pageNumber);
        // Perform any action here, like fetching data for the new page
      }
    };
    const navigate = useNavigate();
    const handleEditClick = (
      campaignId,
      campaignName,
      startDate,
      endDate,
      interval,
      template,
      channel,
      status,
      members
    ) => {
      navigate(
        `/CampaignDetails?campaignId=${campaignId}&interval=${interval}&campaignName=${campaignName}&startDate=${startDate}&endDate=${endDate}&template=${template}&channel=${channel}&status=${status}&members=${members}`
      );
    };
  
    const handleViewClick = (campaignId, campaignName) => {
      navigate(
        `/CampaignView?campaignId=${campaignId}&campaignName=${campaignName}`
      );
    };
    // fetching the campaigns using the Anlook api.
    let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
    const getCampaign = async (e) => {
      try {
        setLoading(true);
  
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
        var raw = JSON.stringify({
          userid: "A1001",
          campaignName: "string",
        });
  
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
  
        const res = await fetch(
          `https://api.anlook.com/api/Campaign/getCampaigns`,
          requestOptions
        );
  
        const data = await res.json();
  
        if (data?.responseCode == "00") {
          setCampaign(data?.data);
        } else {
        }
      } catch (error) {
        console.log({ error });
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };
  
    useEffect(() => {
      getCampaign();
    }, []);
  
    function handleModal(item) {
      setModalData(item);
    }
  
    function handleDelete(item) {
      setdeleteState(item);
      SetExecute();
    }
    function SetExecute() {
      setExcuteDelete("delete");
    }
  
    const DeleteCampaign = async (e) => {
      try {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
  
        var raw = JSON.stringify({
          userid: "A1001",
          secret: "string",
          campaignId: deleteState,
          status: "string",
        });
  
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
  
        const res = await fetch(
          `https://api.anlook.com/api/Campaign/deleteCampaign`,
          requestOptions
        );
  
        const data = await res.json();
        if (deleteState !== "") {
          toast.success(data.responseMessage);
          setErrorDelete(data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };
  
    const onoffapi = async (id, status) => {
      try {
        const myHeaders = {
          "Content-Type": "application/json",
          Authorization: `Basic ${BASIC_AUTH}`,
        };
  
        const requestBody = {
          userid: "A1001",
          secret: "string",
          campaignId: id,
          status: status === "Y" ? "N" : "Y", // Toggle status
        };
  
        const response = await axios.post(
          "https://api.anlook.com/api/Campaign/changeCampaignStatus",
          requestBody,
          { headers: myHeaders }
        );
  
        if (response.data.responseCode === "00") {
          toast.success(response.data.responseMessage);
          getCampaign();
        } else {
          toast.error(response.data.responseMessage);
        }
      } catch (error) {
        console.error("Error toggling campaign status:", error);
      }
    };
  
    useEffect(() => {
      DeleteCampaign();
    }, [ExcuteDelete]);
  
    // pagination values defined here
    const data = Campaign.filter(
      (item) =>
        item.campaign_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.campaign_start?.includes(searchTerm) ||
        item.campaign_end?.includes(searchTerm) ||
        item.campaign_interval?.includes(searchTerm) ||
        item.channel?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
  
    const endIndex =
      currentPage === totalPages ? Campaign?.length : startIndex + itemsPerPage;
    const currentItems = data?.slice(startIndex, endIndex);
  
    // Edit campaign states loading
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      // Initialize tooltips when component mounts
      const tooltips = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      tooltips?.map((tooltip) => new window.bootstrap.Tooltip(tooltip));
  
      // Clean up tooltips when component unmounts
      return () => {
        tooltips?.map((tooltip) => tooltip?.dispose());
      };
    }, []);
    const handleStatusChange = (id, status) => {
      Swal.fire({
        title: `Are you sure you want to ${
          status === "Y" ? "Stop" : "Start"
        } this campaign?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          onoffapi(id, status);
        }
      });
    };
    return (
      <>
        <NoIndex />
        <div className="d-flex justify-content-center text-center">
          <ToastContainer />
        </div>
        <div>
          <div
            className="p-4 bg-white "
            style={{
              height: "100vh",
              fontFamily: "Nunito,sans-serif",
            }}
          >
            <div className=" px-4  justify-content-center">
              <Form className=" d-flex justify-content-between pb-4">
                <Form.Label className="CreateCampaign   ">
                  Manage Campaign
                </Form.Label>
                <Form inline style={{ width: "25rem" }}>
                  <FormControl
                    type="text"
                    placeholder="Search"
                    value={searchTerm}
                    className="bg-light "
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Form>
              </Form>
              {loading ? (
                <div className=" m-5 p-5 d-flex justify-content-center">
                  <div className="loader "></div>
                </div>
              ) : (
                <>
                  <React.Fragment
                    className=""
                    style={{
                      border: "1px solid #dddddd",
                      borderRadius: "10px",
                    }}
                  >
                    <Table responsive className="table table-hover"  style={{
                      border: "1px solid #dddddd",
                      borderRadius: "10px",
                    }}>
                      <thead style={{ borderRadius: "10px" }}>
                        <tr className="text-center greenColour  py-4">
                          <th className="pb-3">S/N</th>
                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            Campaign Name
                          </th>
  
                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            {" "}
                            Start Time
                          </th>
  
                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            End Time
                          </th>
  
                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            Status
                          </th>
  
                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                            }}
                          >
                            Start/Stop
                          </th>
                          <th
                            className="pb-3"
                            style={{
                              color: "#000",
                              // backgroundColor: "rgba(1, 195, 125, 1)",
                              borderRadius: "0px 7px 7px 0px",
                            }}
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
  
                      <tbody>
                        {currentItems?.map((item, index) => (
                          <tr
                            key={index}
                            style={
                              {
                                // borderBottom: "1px solid #DDD",
                                // padding: "5px 0px",
                              }
                            }
                          >
                            <td className="pt-4 text-center">
                              {index + startIndex + 1}
                            </td>
  
                            <td className="pt-4 text-center">{item.campaign_name}</td>
  
                            <td className="pt-4 text-center">
                              {item.campaign_start}
                            </td>
                            <td className="pt-4 text-center">{item.campaign_end}</td>
  
                            <td className="pt-4 text-center">
                              {item.status === "N" ? (
                                <span
                                  className=" text-center w-100  px-3 py-1"
                                  style={{
                                    width: "91px",
                                    height: "31px",
  
                                    borderRadius: "50px",
                                    color: "rgba(255, 72, 72, 1)",
                                    background: " rgba(255, 224, 224, 1)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Stopped
                                </span>
                              ) : (
                                <span
                                  className=" text-center w-100  px-3 py-1"
                                  style={{
                                    width: "91px",
                                    height: "31px",
                                    borderRadius: "50px",
                                    color: " rgba(1, 195, 125, 1)",
                                    background: " rgba(242, 252, 248, 1)",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Running
                                </span>
                              )}
                            </td>
                            <td className="pt-2 text-center">
                              <button
                                onClick={() =>
                                  handleStatusChange(item.id, item.status)
                                }
                                // className=" px-4 btn-success bg-success"
                                className=" g-10 "
                                style={{
                                  width: "120px",
                                  height: "44px",
                                  padding: "10px 25.5px",
                                  gap: "5px",
                                  borderRadius: "10px",
                                  border: "1px solid rgba(136, 136, 136, 1)",
                                  backgroundColor: "#ffff",
                                  borderStyle: "solid",
                                  color: "rgba(136, 136, 136, 1)",
                                }}
                              >
                                {item.status === "Y" ? (
                                  <>
                                    <img className="pe-1" src={pause} />
                                    Stop
                                  </>
                                ) : (
                                  <>
                                    <img className="pe-1" src={Play} />
                                    Start
                                  </>
                                )}
                              </button>
                            </td>
                            <td className=" d-flex justify-content-center ">
                              <div
                                className="d-flex justify-content-center  align-items-center gap-4 "
                                style={{ height: "50px" }}
                              >
                                <span
                                  className="cursor-pointer"
                                  data-bs-placement="bottom"
                                  title="View"
                                  onClick={() => {
                                    handleViewClick(item.id, item.campaign_name);
                                  }}
                                >
                                  <img src={eye} alt="Logo" />
                                </span>
                                <span
                                  className="cursor-pointer"
                                  data-bs-placement="top"
                                  title="Edit"
                                  onClick={() => {
                                    handleEditClick(
                                      item.id,
                                      item.campaign_name,
                                      item.campaign_start,
                                      item.campaign_end,
                                      item.campaign_interval,
                                      item.template_id,
                                      item.channel,
                                      item.status,
                                      item.members
                                    );
                                  }}
                                >
                                  <img src={Edit} value={index} alt="Logo" />
                                </span>
                                {/* <div
                              className="cursor-pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal1"
                              // data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                              onClick={() => {
                                handleModal(item.id);
                              }}
                            >
                              <img src={trash} alt="Logo" />
                            </div> */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </React.Fragment>
                  <>
                    <div
                      class="modal fade"
                      id="exampleModal1"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h1
                              class="modal-title fs-5 text-success"
                              id="exampleModalLabel"
                            >
                              {ModalData}
                            </h1>
                            <button
                              type="button"
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div class="modal-body">
                            Are you sure you want to delete this item? This action
                            cannot be undone.
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                            <button
                              id="liveAlertBtn"
                              type="button"
                              class="btn btn-danger"
                              // data-bs-toggle="modal"
                              // data-bs-target="#exampleModal2"
                              data-bs-dismiss="modal"
                              onClick={() => handleDelete(ModalData)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
  
                    {/* //////////// */}
                    <div className="py-3">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onNextPage={() => handleClick("next")}
                        onPrevPage={() => handleClick("prev")}
                        onPageClick={(e) => handlePageClick(e)}
                      />
                    </div>
                  </>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  // Payment Logs Component
  const PaymentLogs = ({ userID }) => {
    const [templates, setTemplates] = useState([]);
    const [deleteState, setdeleteState] = useState("");
    const [ErrorDelete, setErrorDelete] = useState();
  
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      userid: userID,
    });
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [ModalData, setModalData] = useState("");
    const itemsPerPage = 10; // Show 10 items per page
    const [searchTerm, setSearchTerm] = useState("");
    const [ExcuteDelete, setExcuteDelete] = useState("");
    let BASIC_AUTH = "YW5sb29rQGdtYWlsLmNvbTpBbmxvb2tAMTIzNDU=";
  
    const handleClick = (type) => {
      if (type === "prev") {
        setCurrentPage(currentPage === 1 ? 1 : currentPage - 1);
      } else if (type === "next") {
        setCurrentPage(currentPage === totalPages ? totalPages : currentPage + 1);
      }
    };
    const handlePageClick = (pageNumber) => {
      if (
        pageNumber !== currentPage &&
        pageNumber >= 1 &&
        pageNumber <= totalPages
      ) {
        setCurrentPage(pageNumber);
        // Perform any action here, like fetching data for the new page
      }
    };
  
    const navigate = useNavigate();
  
    function handleView(id, name) {
      navigate(`/TemplateView?templateID=${id}&templateName=${name}`);
    }
    function handleEdit(index) {
      navigate(`/WhatsApp/Template/TemplateEdit?templateId=${index}`);
    }
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
  
    let url = `https://api.anlook.com/api/Whatsapp/getTemplates`;
    async function fetchJson(url, a) {
      try {
        let response = await fetch(url, a);
        if (!response.ok) {
          throw new Error("Failed to fetch data. Status:", response.status);
        }
        const jsonData = await response.json({
          userid: "A1001",
          secret: "string",
        });
        return jsonData;
      } catch (error) {
        console.error("An json function error occurred:", error);
        return null;
      }
    }
  
    const getVendorAccounts = async (e) => {
      try {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
        var raw = JSON.stringify({
          userid: "A1001",
        });
  
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
  
        const res = await fetch(
          `https://api.anlook.com/api/Whatsapp/getTemplates`,
          requestOptions
        );
  
        const data = await res.json();
  
        if (data?.responseCode == "00") {
          setTemplates(data?.response.data);
        } else {
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };
    useEffect(() => {
      getVendorAccounts();
    }, []);
  
    const data = templates?.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.language.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const data2 = templates.filter((item) =>
      item.language.toLowerCase().includes(searchTerm.toLowerCase())
    );
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex =
      currentPage === totalPages ? templates?.length : startIndex + itemsPerPage;
    const currentItems = data?.slice(startIndex, endIndex);
  
    // edit delete and view hover diaogbox
    useEffect(() => {
      // Initialize tooltips when component mounts
      const tooltips = [].slice.call(
        document.querySelectorAll('[data-bs-toggle="tooltip"]')
      );
      tooltips?.map((tooltip) => new window.bootstrap.Tooltip(tooltip));
  
      // Clean up tooltips when component unmounts
      return () => {
        tooltips?.map((tooltip) => tooltip?.dispose());
      };
    }, []);
  
    function handleModal(item) {
      setModalData(item);
    }
    function handleDelete(item) {
      setdeleteState(item);
      SetExecute();
    }
    function SetExecute() {
      setExcuteDelete("delete");
    }
  
    const DeleteTemplate = async (e) => {
      try {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Basic ${BASIC_AUTH}`);
  
        var raw = JSON.stringify({
          userid: "A1001",
          secret: "string",
          templateId: deleteState,
        });
  
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
  
        const res = await fetch(
          `https://api.anlook.com/api/Whatsapp/deleteTemplate`,
          requestOptions
        );
  
        const data = await res.json();
        if (deleteState !== "") {
          toast.success(data.responseMessage);
          setErrorDelete(data.responseMessage);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };
  
    useEffect(() => {
      DeleteTemplate();
    }, [ExcuteDelete]);
  
    return (
      <>
        <NoIndex />
        <div className="d-flex justify-content-center text-center">
          <ToastContainer />
        </div>
        <div
          className="p-4 bg-white"
          style={{
            // backgroundColor: "#F8F8F8",
            fontFamily: "Nunito, sans-serif",
          }}
        >
          <div className="bg-white px-4">
            <div className="  " style={{ borderRadius: "10px" }}>
              <Form className=" d-flex justify-content-between pb-4">
                <Form.Label className="CreateCampaign  ">
                  Template Status
                </Form.Label>
                <Form inline style={{ width: "25rem" }}>
                  <FormControl
                    type="text"
                    placeholder="Search"
                    className="bg-light "
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Form>
              </Form>
              {loading ? (
                <div className=" m-5 p-5 d-flex justify-content-center">
                  <div className="loader "></div>
                </div>
              ) : (
                <React.Fragment
                  className=""
                  style={{
                    border: "1px solid #dddddd",
                    borderRadius: "10px",
                  }}
                >
                  <Table
                  responsive
                    className="table table-hover"
                    style={{
                      border: "1px solid #dddddd",
                      borderRadius: "10px",
                    }}
                  >
                    <thead style={{ borderRadius: "10px" }}>
                      <tr className="text-center greenColour  py-4">
                        <th className="pb-3">S/N</th>
                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            // backgroundColor: "rgba(1, 195, 125, 1)",
                          }}
                        >
                          Template Name
                        </th>
  
                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            // backgroundColor: "rgba(1, 195, 125, 1)",
                          }}
                        >
                          {" "}
                          Language
                        </th>
  
                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            // backgroundColor: "rgba(1, 195, 125, 1)",
                          }}
                        >
                          Category
                        </th>
  
                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            // backgroundColor: "rgba(1, 195, 125, 1)",
                          }}
                        >
                          Status
                        </th>
  
                       
                        <th
                          className="pb-3"
                          style={{
                            color: "#000",
                            // backgroundColor: "rgba(1, 195, 125, 1)",
                            borderRadius: "0px 7px 7px 0px",
                          }}
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
  
                    <tbody>
                      {currentItems?.map((item, index) => (
                        <tr
                          key={index}
                          style={
                            {
                              // borderBottom: "1px solid #DDD",
                              // padding: "5px 0px",
                            }
                          }
                        >
                          <td className="pt-4 text-center">
                            {index + startIndex + 1}
                          </td>
  
                          <td className="pt-4 text-center">{item.name}</td>
  
                          <td className="pt-4 text-center">{item.language}</td>
                          <td className="pt-4 text-center">{item.category}</td>
  
                          <td className="pt-4 text-center">
                            {item.status === "APPROVED" ? (
                              <span
                                className=" text-center w-100  px-3 py-1"
                                style={{
                                  width: "91px",
                                  height: "31px",
                                  borderRadius: "50px",
                                  color: " rgba(1, 195, 125, 1)",
                                  background: " rgba(242, 252, 248, 1)",
                                  fontWeight: "bold",
                                }}
                              >
                                Approved
                              </span>
                            ) : (
                              <span
                                className=" text-center w-100  px-3 py-1"
                                style={{
                                  width: "91px",
                                  height: "31px",
  
                                  borderRadius: "50px",
                                  color: "rgba(255, 72, 72, 1)",
                                  background: " rgba(255, 224, 224, 1)",
                                  fontWeight: "bold",
                                }}
                              >
                                Disapproved
                              </span>
                            )}
                          </td>
                      
                          <td className=" d-flex justify-content-center ">
                            <div
                              className="d-flex justify-content-center align-items-center gap-4"
                              style={{ height: "50px" }}
                            >
                              <span
                                className="cursor-pointer"
                                data-bs-placement="bottom"
                                title="View"
                                onClick={() => {
                                  handleView(item.id, item.name);
                                }}
                              >
                                <img src={eye} alt="Logo" />
                              </span>
                              <span
                                className="cursor-pointer"
                                data-bs-placement="top"
                                title="Edit"
                                onClick={() => {
                                  handleEdit(index, item.name);
                                }}
                              >
                                <img src={Edit} value={index} alt="Logo" />
                              </span>
                              {/* <div
                          className="cursor-pointer"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal1"
                          // data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete"
                          onClick={() => {
                            handleModal(item.id);
                          }}
                        >
                          <img src={trash} alt="Logo" />
                        </div> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </React.Fragment>
              )}
            </div>
            <>
              <div
                class="modal fade"
                id="exampleModal1"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1
                        class="modal-title fs-5 text-success"
                        id="exampleModalLabel"
                      >
                        {ModalData}
                      </h1>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      Are you sure you want to delete this item? This action
                      cannot be undone.
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        id="liveAlertBtn"
                        type="button"
                        class="btn btn-danger"
                        data-bs-dismiss="modal"
                        onClick={() => handleDelete(ModalData)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
  
            {/* {pagination} */}
            <div>
              {loading === false ? (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onNextPage={() => handleClick("next")}
                  onPrevPage={() => handleClick("prev")}
                  onPageClick={(e) => handlePageClick(e)}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  // Order Logs Component
  const OrderLogs = () => {
    const [startDate, setStartDate] = useState(getOneWeekBackDate());
    const [endDate, setEndDate] = useState(getCurrentDate());
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;

    const orderData = [
      { id: 1, orderId: "ORD001", customer: "John Doe", items: 3, total: 150, date: "2024-03-25", status: "shipped" },
      { id: 2, orderId: "ORD002", customer: "Jane Smith", items: 2, total: 95, date: "2024-03-24", status: "processing" },
      { id: 3, orderId: "ORD003", customer: "Bob Johnson", items: 1, total: 50, date: "2024-03-23", status: "delivered" },
    ];

    const totalPages = Math.ceil(orderData.length / itemsPerPage);

    return (
      <LogTable
        title="Order Logs"
        loading={false}
        data={orderData}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        columns={orderColumns}
      />
    );
  };

  // Reusable Log Table Component
  const LogTable = ({
    title,
    loading,
    data,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    currentPage,
    setCurrentPage,
    totalPages,
    columns
  }) => {
    const handlePageClick = (page) => setCurrentPage(page);
    const handleNextPage = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
    const handlePrevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);

    return (
      <div className="p-4 bg-white" style={{ height: "100vh", fontFamily: "Nunito,sans-serif" }}>
        <div className="px-4 justify-content-center">
          <Form className="d-flex justify-content-between pb-4">
            <Form.Label className="CreateCampaign">{title}</Form.Label>
            <div className="d-flex">
              <label htmlFor="start-date" className="single-line-css d-flex align-items-center">
                Start Date
              </label>
              <FormControl
                type="date"
                id="start-date"
                value={startDate}
                className="bg-light me-5 ms-2"
                onChange={(e) => setStartDate(e.target.value)}
              />
              <label htmlFor="end-date" className="single-line-css d-flex align-items-center">
                End Date
              </label>
              <FormControl
                type="date"
                id="end-date"
                value={endDate}
                className="bg-light ms-2"
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </Form>
          
          {loading ? (
            <div className="m-5 p-5 d-flex justify-content-center">
              <div className="loader"></div>
            </div>
          ) : (
            <>
              <Table responsive className="table table-hover">
                <thead>
                  <tr className="text-center greenColour">
                    {columns.map((column, index) => (
                      <th
                        key={index}
                        className="p-3"
                        style={{
                          color: "#fff",
                          backgroundColor: "rgb(25,135,84)",
                          borderRadius: index === 0 ? "7px 0px 0px 7px" : 
                                       index === columns.length - 1 ? "0px 7px 7px 0px" : "",
                        }}
                      >
                        {column.header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid #DDD" }}>
                      {columns.map((column, colIndex) => (
                        <td key={colIndex} className="p-3 text-center">
                          {column.render ? column.render(item) : item[column.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="pb-4">
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onNextPage={handleNextPage}
                  onPrevPage={handlePrevPage}
                  onPageClick={handlePageClick}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  // Utility functions
  const getCurrentDate = () => {
    const today = new Date();
    return today.toISOString().split('T')[0];
  };

  const getOneWeekBackDate = () => {
    const oneWeekBack = new Date();
    oneWeekBack.setDate(oneWeekBack.getDate() - 7);
    return oneWeekBack.toISOString().split('T')[0];
  };

  // Column definitions
  const whatsAppColumns = [
    { header: 'S.No.', key: 'index', render: (_, index) => index + 1 },
    { header: 'Type', key: 'category' },
    { header: 'Name', key: 'contact_name' },
    { header: 'Mobile Number', key: 'contact' },
    { header: 'Time', key: 'indate' },
    {
      header: 'Status',
      key: 'status',
      render: (item) => (
        <span
          className="badge text-center w-100 p-2"
          style={{
            color: "#ffff",
            border: `solid 1px ${item.status === "read" ? "#007E59" : "#000"}`,
            borderRadius: "5px",
          }}
        >
          {item.status}
        </span>
      ),
    },
  ];

  const paymentColumns = [
    { header: 'S.No.', key: 'index', render: (_, index) => index + 1 },
    { header: 'Transaction ID', key: 'transactionId' },
    { header: 'Amount', key: 'amount', render: (item) => `$${item.amount}` },
    { header: 'Method', key: 'method' },
    { header: 'Date', key: 'date' },
    {
      header: 'Status',
      key: 'status',
      render: (item) => (
        <span
          className="badge text-center w-100 p-2"
          style={{
            color: "#ffff",
            border: "solid 1px #007E59",
            borderRadius: "5px",
          }}
        >
          {item.status}
        </span>
      ),
    },
  ];

  const orderColumns = [
    { header: 'S.No.', key: 'index', render: (_, index) => index + 1 },
    { header: 'Order ID', key: 'orderId' },
    { header: 'Customer', key: 'customer' },
    { header: 'Items', key: 'items' },
    { header: 'Total', key: 'total', render: (item) => `$${item.total}` },
    { header: 'Date', key: 'date' },
    {
      header: 'Status',
      key: 'status',
      render: (item) => (
        <span
          className="badge text-center w-100 p-2"
          style={{
            color: "#ffff",
            border: "solid 1px #007E59",
            borderRadius: "5px",
          }}
        >
          {item.status}
        </span>
      ),
    },
  ];

  return (
    <div className="marginRight mt-4">
      <div className="container-fluid text-center">
        <ToastContainer />
      </div>
      <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        <Nav variant="tabs" className="mb-3">
          <Nav.Item>
            <Nav.Link eventKey="campaign">Campaigns</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="templates">Templates</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="order">Order Logs</Nav.Link>
          </Nav.Item> */}
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="campaign">
            <WhatsAppLogs />
          </Tab.Pane>
          <Tab.Pane eventKey="templates">
            <PaymentLogs />
          </Tab.Pane>
          <Tab.Pane eventKey="order">
            <OrderLogs />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default WhatsAppLogs;