import React from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import { 
  BarChart, Bar, LineChart, Line, PieChart, Pie, Cell,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer 
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUsers, faFileAlt, faCheckCircle, 
  faTimesCircle, faShoppingCart, faStore,
  faBuilding, faIdCard, faCode, faChartLine,
  faCalendarAlt, faRupeeSign,
  faBriefcase,  faUser
} from '@fortawesome/free-solid-svg-icons';
import "./AdminDashboard.css"


const AdminDashboard = () => {
   // Dummy data with Indian context
   const campaignData = [
    { month: 'Jan', created: 45, running: 32 },
    { month: 'Feb', created: 55, running: 42 },
    { month: 'Mar', created: 65, running: 48 },
    { month: 'Apr', created: 75, running: 58 }
  ];

  const templateStats = {
    created: 250,
    approved: 180,
    rejected: 70
  };

  const orderStats = {
    total: 1250,
    completed: 980,
    refunded: 45,
    pending: 225
  };

  const topProducts = [
    { name: 'Basmati Rice', orders: 450 },
    { name: 'Masala Chai', orders: 380 },
    { name: 'Spice Mix', orders: 320 },
    { name: 'Ghee', orders: 290 },
    { name: 'Dal Mix', orders: 250 }
  ];

  const userStats = {
    totalUsers: 5840,
    activeUsers: 4250,
    inactiveUsers: 1590
  };

  const catalogueItems = {
    total: 856,
    active: 725,
    outOfStock: 131
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
  // Client Overview Data
  const clientOverview = {
    name: "Tata Consumer Products Ltd",
    code: "TCPL2024",
    id: "IND456789",
    status: "active",
    subscription: "Enterprise",
    joinDate: "15 Jan 2024",
    lastActive: "2 hours ago",
    billingCycle: "Annual",
    revenueYTD: "₹1.2Cr",
    activeProjects: 12,
    keyMetrics: {
      monthlyUsers: 12500,
      monthlyOrders: 8750,
      avgOrderValue: "₹2,850",
      retentionRate: "92%"
    }
  };


  // Rest of the existing data objects remain the same...

  return (
    <div fluid className="p-4 marginRight">
      {/* Client Overview Section */}
      <Card className="mb-4 shadow-none border bg-light w-100" style={{backgroundColor:"#E7F2ED"}}>
        <Card.Body>
          {/* Client Header */}
          <Row className="mb-4">
            <Col md={8}>
              <div className="d-flex align-items-center mb-3">
                <FontAwesomeIcon icon={faBuilding} className="text-primary me-3" size="2x" />
                <div>
                  <h2 className="mb-0">{clientOverview.name}</h2>
                  <div className="text-muted">
                    <small className="me-3">
                      <FontAwesomeIcon icon={faIdCard} className="me-2" />
                      ID: {clientOverview.id}
                    </small>
                    <small className="me-3">
                      <FontAwesomeIcon icon={faCode} className="me-2" />
                      Code: {clientOverview.code}
                    </small>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4} className="text-md-end d-flex align-items-center">
              <Badge 
                bg={clientOverview.status === 'active' ? 'success' : 'warning'}
                className="mb-2 text-uppercase px-3 py-2"
              >
                {clientOverview.status}
              </Badge>
           
            </Col>
          </Row>

          {/* Key Metrics Grid */}
          <Row className="g-3">
  <Col md={7}>
    <Row className="g-3">
    <Col md={4} sm={6}>
        <Card className="bg-white h-100 shadow-none border" style={{width:"15rem"}}>
          <Card.Body>
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
              <h6 className="mb-0">Status</h6>
            </div>
            <h3 className="mb-1">{clientOverview.status.charAt(0).toUpperCase() + clientOverview.status.slice(1)}</h3>
            <small className="text-success">This client is Active </small>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} sm={6}>
        <Card className="bg-white h-100 shadow-none border" style={{width:"15rem"}}>
          <Card.Body>
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faBriefcase} className="text-danger me-2" />
              <h6 className="mb-0">Subcription</h6>
            </div>
            <h3 className="mb-1">{clientOverview.subscription}</h3>
            <small className="text-success">Current plan</small>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} sm={6}>
        <Card className="bg-white h-100 shadow-none border" style={{width:"15rem"}}>
          <Card.Body>
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faUsers} className="text-secondary me-2" />
              <h6 className="mb-0">Monthly Users</h6>
            </div>
            <h3 className="mb-1">{clientOverview.keyMetrics.monthlyUsers.toLocaleString()}</h3>
            <small className="text-success">↑ 12% from last month</small>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} sm={6}>
        <Card className="bg-white h-100 shadow-none border" style={{width:"15rem"}}>
          <Card.Body>
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faShoppingCart} className="text-dark me-2" />
              <h6 className="mb-1">Monthly Orders</h6>
            </div>
            <h3 className="mb-0">{clientOverview.keyMetrics.monthlyOrders.toLocaleString()}</h3>
            <small className="text-success">↑ 8% from last month</small>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} sm={6}>
        <Card className="bg-white h-100 shadow-none border" style={{width:"15rem"}}>
          <Card.Body>
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faRupeeSign} className="text-warning me-2" />
              <h6 className="mb-0">Avg Order Value</h6>
            </div>
            <h3 className="mb-1">{clientOverview.keyMetrics.avgOrderValue}</h3>
            <small className="text-success">↑ 5% from last month</small>
          </Card.Body>
        </Card>
      </Col>
      <Col md={4} sm={6}>
        <Card className="bg-white h-100 shadow-none border" style={{width:"15rem"}}>
          <Card.Body>
            <div className="d-flex align-items-center mb-4">
              <FontAwesomeIcon icon={faChartLine} className="text-info me-2" />
              <h6 className="mb-0">Retention Rate</h6>
            </div>
            <h3 className="mb-1">{clientOverview.keyMetrics.retentionRate}</h3>
            <small className="text-success">↑ 2% from last month</small>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Col>
  
  {/* New Additional Info Card */}
  <Col md={5}>
    <Card className="bg-white shadow-none border h-100 w-100">
      <Card.Body className="d-flex flex-column justify-content-between">
        <h4 className="mb-3 text-muted"><FontAwesomeIcon icon={faUser} className="me-2 text-success" />
        Client Information</h4>
        <hr className='m-0 p-0' />
        
        <div className="mb-3 d-flex justify-content-between">
          <div> <div className="d-flex align-items-center mb-3">
            <FontAwesomeIcon icon={faCalendarAlt} className="me-2 text-muted" />
            <div>
              <small className="text-muted">Joined</small>
              <div>{clientOverview.joinDate}</div>
            </div>
          </div>
          
          <div className="d-flex align-items-center mb-3">
            <FontAwesomeIcon icon={faFileAlt} className="me-2 text-muted" />
            <div>
              <small className="text-muted">Billing</small>
              <div>{clientOverview.billingCycle}</div>
            </div>
          </div></div>
          <div>  <div className="d-flex align-items-center mb-3">
            <FontAwesomeIcon icon={faRupeeSign} className="me-2 text-muted" />
            <div>
              <small className="text-muted">YTD Revenue</small>
              <div>{clientOverview.revenueYTD}</div>
            </div>
          </div>
          
          <div className="d-flex align-items-center mb-3">
            <FontAwesomeIcon icon={faCheckCircle} className="me-2 text-muted" />
            <div>
              <small className="text-muted">Active Projects</small>
              <div>{clientOverview.activeProjects}</div>
            </div>
          </div></div>

         
          
        
          
        
        </div>
        
        <small className="text-muted">
          Last Active: {clientOverview.lastActive}
        </small>
      </Card.Body>
    </Card>
  </Col>
</Row>


         
        </Card.Body>
      </Card>

      {/* Rest of the existing dashboard content remains the same... */}
      {/* Quick Stats Cards */}
      <div className="mb-4 d-flex justify-content-between">
        {/* Existing cards code... */}
        <Col lg={4} md={6} className="mb-4">
          <Card className="h-100 shadow-none" style={{width :"25rem"}}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6 className="text-muted mb-2">Total Users</h6>
                  <h3 className="mb-0">{userStats.totalUsers.toLocaleString()}</h3>
                  <small className="text-success">
                    {((userStats.activeUsers / userStats.totalUsers) * 100).toFixed(1)}% Active
                  </small>
                </div>
                <FontAwesomeIcon icon={faUsers} size="2x" className="text-primary opacity-50" />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} md={6} className="mb-4">
          <Card className="h-100 shadow-none" style={{width:"25rem"}}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6 className="text-muted mb-2">Total Orders</h6>
                  <h3 className="mb-0">{orderStats.total.toLocaleString()}</h3>
                  <small className="text-success">
                    {((orderStats.completed / orderStats.total) * 100).toFixed(1)}% Completed
                  </small>
                </div>
                <FontAwesomeIcon icon={faShoppingCart} size="2x" className="text-success opacity-50" />
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={4} md={6} className="mb-4">
          <Card className="h-100 shadow-none"style={{width:"25rem"}}>
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h6 className="text-muted mb-2">Catalogue Items</h6>
                  <h3 className="mb-0">{catalogueItems.total.toLocaleString()}</h3>
                  <small className="text-warning">
                    {catalogueItems.outOfStock} items out of stock
                  </small>
                </div>
                <FontAwesomeIcon icon={faStore} size="2x" className="text-warning opacity-50" />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>

      {/* Charts Row */}
      <Row className="mb-4">
        {/* Existing charts code... */}
        <Col lg={8} className="mb-4">
          <Card className="h-100 shadow-none w-100">
            <Card.Body>
              <h5 className="mb-4">Campaign Performance</h5>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={campaignData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="created" stroke="#8884d8" name="Created" />
                  <Line type="monotone" dataKey="running" stroke="#82ca9d" name="Running" />
                </LineChart>
              </ResponsiveContainer>
            </Card.Body>
            <div className='m-3'> <a  style={{color:"blue",cursor:"pointer",textDecoration:"underline"}}>View Leads</a></div>
           

          </Card>
        </Col>

        <Col lg={4} className="mb-4">
          <Card className="h-100 shadow-none w-100">
            <Card.Body>
              <h5 className="mb-4">Template Status</h5>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={[
                      { name: 'Approved', value: templateStats.approved },
                      { name: 'Rejected', value: templateStats.rejected }
                    ]}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {COLORS.map((color, index) => (
                      <Cell key={`cell-${index}`} fill={color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Card.Body>
            <div className='m-3'> <a  style={{color:"blue",cursor:"pointer",textDecoration:"underline"}}>View Leads</a></div>
           
          </Card>
        </Col>
      </Row>

      {/* Top Products Chart */}
      <Row>
        {/* Existing top products chart code... */}
        <Col lg={12} className="mb-4">
          <Card className="shadow-none w-100">
            <Card.Body>
              <h5 className="mb-4">Top Ordered Products</h5>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={topProducts}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="orders" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;
